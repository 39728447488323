import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material"

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Oval } from "react-loader-spinner";
import { useEffect, useState } from "react";

const AddressForm = ({ handleForm, loading, closeForm, mode, address }) => {

    const [formData, setFormData] = useState({
        uname: '',
        addr: '',
        ph: '',
        zip: '',
        type: '',
        attn: ''
    })
    const [formErrors, setFormErrors] = useState({
        uname: '',
        ph: '',
        addr: '',
        zip: '',
        type: '',
        attn: ''
    });

    useEffect(() => {
        if (mode) {

            setFormData(prevdata => ({
                ...prevdata,
                uname: address.uname,
                addr: address.addr,
                ph: address.ph,
                zip: address.zip,
                type: address.type,
                attn: address.attn
            }))
        }
    }, [address, mode]);


    const isFormValid = () => {
        return Object.values(formErrors).every((error) => error === '') &&
            Object.values(formData).every((value) => value.trim() !== '');
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        //console.log(e)
        setFormData({ ...formData, [id]: value })
        validateField(id, value)
    }

    const validateField = (fieldName, value) => {
        let errorMessage = '';
        //console.log(fieldName, value)
        // Perform validation based on the field name
        switch (fieldName) {

            case 'uname':
                if (value.trim() === '') {
                    errorMessage = 'Name is required';
                } else if(value.length < 5 || value.length > 30) {
                    errorMessage = 'Min 5 & Max 30 characters are allowed.';
                }
                break;
            case 'attn':
                if (value.trim() === '') {
                    errorMessage = 'Attendant is required';
                } else if(value.length < 5 || value.length > 30) {
                    errorMessage = 'Min 5 & Max 30 characters are allowed.';
                }
                break;
            case 'ph':
                if (value.trim() === '') {
                    errorMessage = 'Phone number is required';
                } else if (!/\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/.test(value)) {
                    errorMessage = 'Invalid phone number';
                }
                break;
            case 'addr':
                if (value.trim() === '') {
                    errorMessage = 'Address is required';
                } else if(value.length < 5 || value.length > 100) {
                    errorMessage = 'Min 5 & Max 100 characters are allowed.';
                }
                break;
            case 'zip':
                if (value.trim() === '') {
                    errorMessage = 'Zip code is required';
                } else if (!/^\d{5}(?:[-\s]\d{4})?$/.test(value)) {
                    errorMessage = 'Invalid zip code';
                }
                break;
            case 'type':
                if (value.trim() === '') {
                    console.log(value)
                    errorMessage = 'Address type is required.'
                }
                break;
            default:
                break;
        }

        // Update the formErrors state
        setFormErrors({ ...formErrors, [fieldName]: errorMessage });
    };

    const formDataChange = () => {
        //console.log(formData)
        let params = {
            mode: mode,
            data: mode ? { ...formData, address_id: address.address_id } : formData
        }

        handleForm(params)
    }



    return (
        <Box
            width={'100%'}
            display={'flex'}
            justifyContent={'center'}
        >

            <Box
                width={'60%'}
                p={'1.5rem'}
                display={'flex'}
                flexDirection={'column'}
                //alignItems={'center'}
                gap={'15px'}
            >
                <Typography variant="h4" >
                    {
                        mode ? 'Edit Address' : 'Add Address'
                    }
                </Typography>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    formDataChange()

                }}
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '15px'
                    }}
                >
                    <TextField
                        id="uname"
                        label="Company Name"
                        variant="filled"
                        value={formData.uname}
                        onChange={handleChange}
                        error={formErrors.uname !== ''}
                        helperText={formErrors.uname}
                        disabled={loading}

                    />
                    <TextField
                        id="attn"
                        label="Attendant"
                        variant="filled"
                        value={formData.attn}
                        onChange={handleChange}
                        error={formErrors.attn !== ''}
                        helperText={formErrors.attn}
                        disabled={loading}

                    />
                    <TextField
                        id="ph"
                        label="Phone number"
                        variant="filled"
                        value={formData.ph}
                        onChange={handleChange}
                        error={formErrors.ph !== ''}
                        helperText={formErrors.ph}
                        disabled={loading}
                    />
                    <TextField
                        id="addr"
                        label="Address"
                        variant="filled"
                        value={formData.addr}
                        onChange={handleChange}
                        error={formErrors.addr !== ''}
                        helperText={formErrors.addr}
                        disabled={loading}
                    />
                    <TextField
                        id="zip"
                        label="Zip Code"
                        variant="filled"
                        value={formData.zip}
                        onChange={handleChange}
                        error={formErrors.zip !== ''}
                        helperText={formErrors.zip}
                        disabled={loading}
                    />
                    <Box
                        gridColumn="span 4"
                    >
                        <FormControl sx={{ width: '100%' }} size="small" variant="filled">
                            <InputLabel id="type">Address type</InputLabel>
                            <Select
                                id="type"
                                labelId="type"
                                value={formData.type ?? ""}
                                name="type"
                                label="Address type"
                                onChange={(e) =>{
                                    e.target.id = 'type'
                                    handleChange(e)
                                }}
                                disabled={loading}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value='shipping'>Shipping</MenuItem>
                                <MenuItem value='invoice'>Invoice</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            gap: '10px'
                        }}
                    >

                        <Button variant="contained" onClick={closeForm} disabled={loading}>
                            Cancel
                        </Button>
                        {
                            !loading ? (
                                <Button variant="outlined" color="secondary" type="submit" disabled={!isFormValid()}>
                                    {
                                        !mode ? 'Add' : 'Update'
                                    }
                                </Button>
                            ) : (
                                <Button variant="outlined" color="secondary" disabled={true} sx={{ display: 'flex', gap: '5px', flexDirection: 'row' }}>
                                    <Oval
                                        height={20}
                                        width={20}
                                    >

                                    </Oval>
                                    {mode ? 'Updating...' : 'Saving...'}
                                </Button>
                            )
                        }

                    </Box>
                </form>
            </Box>
        </Box>
    )
}


export default AddressForm