import { Box, Typography } from "@mui/material"


const Error = ({ apiError }) => {

    console.log(apiError)
    return (
        <Box m="5px" display="flex" justifyContent="center" alignItems="center">
            <Typography variant="h5" color="red">{apiError.message ? apiError.message: 'Something went wrong'}</Typography>
        </Box>
    )
}
export default Error