import { Box, IconButton, InputBase, ToggleButton, ToggleButtonGroup, Typography, useMediaQuery, useTheme } from '@mui/material'
import { tokens } from '../../theme';
import SearchIcon from "@mui/icons-material/Search";
import { useContext, useEffect, useRef, useState } from 'react';
import { endpoints } from '../../data/api';
import Error from "../../components/error";
import Loader from '../../components/Loader';
import { useTransition } from 'react-spring';

import RefreshIcon from '@mui/icons-material/Refresh';
import GridViewIcon from '@mui/icons-material/GridView';
import ListIcon from '@mui/icons-material/List';
import FilterCategory from './filter-category';
import ProductCard from './product-card';
import { CartContext } from '../cart/cart-context';
import api from '../../data/axios-api';

const Products = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    //const isNonMobile = useMediaQuery("(min-width:650px)");
    const mdScreen = useMediaQuery(theme.breakpoints.up("md"));
    const smScreen = useMediaQuery(theme.breakpoints.up("sm"));


    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('no error');
    const [alignment, setAlignment] = useState('grid');
    const hasMounted = useRef(false);
    const [update, setUpdate] = useState(false)
    const [fadeIn, setFadeIn] = useState(true);
    const [imageLoading, setImageLoading] = useState(true)
    const [original, setOriginal] = useState([])
    const { toggleCart } = useContext(CartContext)
    /* const props = useSpring({
        opacity: 1,
        from: { opacity: 0 },
        config: {duration: 100}
    }) */

    const transition = useTransition(fadeIn, {
        from: { y: 30, opacity: 0 },
        enter: { y: 0, opacity: 1 },
        config: { duration: 150 },
        //leave: { opacity: 1 },
        onRest: () => { setFadeIn(true) }
    })



    useEffect(() => {
        if (!hasMounted.current) {
            setLoading(true);
            api.get(`${endpoints.product.price}`, {

            }).then(res => {
                setProducts((prev) => res['data'])
                setOriginal(JSON.stringify(res['data']))
                console.log(original);
            }).catch(err => {
                console.log(err);
                setError(err);
            }).finally(() => {
                setLoading(false);
                setUpdate(false)
            })
        }
        hasMounted.current = true;

        toggleCart(false)

    }, [update, original, toggleCart, error]);


    const refresh = () => {
        console.log('i am here...')
        hasMounted.current = false;
        setUpdate(true)
    }

    const changeView = (e, newAlignment) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment)
        }
    }

    const filterProducts = (e) => {
        const mp = JSON.parse(original);
        if (e === null) {
            console.log(e);
            setProducts(mp)
        } else {
            const filter = mp.filter(p => p.cat === e);
            console.log(filter)
            setProducts(filter)
        }
    }


    return (
        <Box
        >
            {error !== "no error" ? (<Error setError={error} />) :
                loading ? (<Loader />) : (
                    <Box
                        display={'flex'}
                        justifyContent={'center'}
                        width={'100%'}
                        position={'relative'}
                        flexDirection={'row'}
                        gap={'10px'}
                        pr={'4rem'}
                        paddingTop={'80px'}
                    >
                        <Box
                            sx={{
                                width: '20%',
                                marginTop: '2.5rem',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                pl: '2rem'
                            }}
                        >
                            <Box
                                display={'flex'}
                                width={'100%'}
                                justifyContent={'flex-start'}
                                
                                alignItems={'center'}
                                paddingBottom={'1rem'}
                            //borderBottom={`1px solid ${colors.grey[600]}`}
                            >
                                <Typography variant='h4' color={colors.blueAccent[500]} fontWeight={'bold'}>
                                    Filter by
                                </Typography>
                            </Box>
                            <FilterCategory products={products} filterChange={filterProducts} />
                        </Box>
                        {/* side bar ends */}
                        <Box
                            width={'80%'}
                            marginTop={'2rem'}
                            position={'relative'}
                            display={'flex'}
                            flexDirection={'column'}
                            justifyContent={'center'}
                            alignItems={'flex-start'}
                            pl={alignment === 'list' ? '4rem': '4rem'}
                            pr={alignment === 'list' ? '4rem': '4rem'}
                        >
                            <Box
                                display={'flex'}
                                width={'100%'}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                                paddingBottom={'1rem'}
                            //borderBottom={`1px solid ${colors.grey[600]}`}
                            >
                                <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                    <ToggleButtonGroup
                                        color="primary"
                                        value={alignment}
                                        exclusive
                                        onChange={changeView}
                                        aria-label="Platform"
                                        sx={{
                                            '& .Mui-selected': {
                                                color: `${colors.greenAccent[600]} !important`
                                            }
                                        }}
                                    >
                                        <ToggleButton value="grid"> <GridViewIcon /></ToggleButton>
                                        <ToggleButton value="list"><ListIcon /></ToggleButton>
                                    </ToggleButtonGroup>
                                    <Typography variant='h4' color={colors.blueAccent[500]} fontWeight={'bold'}>
                                        Products ({products.length})
                                    </Typography>
                                </div>
                                <Box
                                    display={'flex'}
                                    width={'40%'}
                                    gap={'15px'}
                                >
                                    <Box
                                        display="flex"
                                        width={'100%'}
                                        backgroundColor={colors.primary[400]}
                                        p={0.2}
                                        borderRadius={1}
                                    >
                                        <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Search" />
                                        <IconButton type="button">
                                            <SearchIcon />
                                        </IconButton>

                                    </Box>
                                    <IconButton type="button" variant="filled" onClick={refresh}>
                                        <RefreshIcon />
                                    </IconButton>
                                </Box>
                            </Box> {/* product search bar ends */}


                            <Box
                                height={'30px'}>

                            </Box>
                            {/* {transition((style, item) =>
                                item ? <animated.div style={style} >
                                   
                                </animated.div> : ''
                            )} */}
                            {
                                alignment === 'list' ? (

                                    products.map((product, i) => (
                                        <ProductCard
                                            key={i}
                                            product={product}
                                            imageLoading={imageLoading}
                                            setImageLoading={setImageLoading}
                                            isCart={false}
                                            viewType={alignment}

                                        />
                                    ))
                                ) : (
                                    <Box
                                        display={'grid'}
                                        gridTemplateColumns={'repeat(5, 1fr)'}
                                        gap={'15px'}
                                        sx={{
                                            '@media (min-width: 1440px)': {
                                                'gridTemplateColumns': 'repeat(4, 1fr)'
                                            },
                                            '@media (max-width: 1280px)': {
                                                'gridTemplateColumns': 'repeat(3, 1fr)'
                                            },
                                            '@media (max-width: 1024px)': {
                                                'gridTemplateColumns': 'repeat(3, 1fr)'
                                            },
                                            '@media (max-width: 768px)': {
                                                'gridTemplateColumns': 'repeat(2, 1fr)'
                                            },
                                            '@media (max-width: 480px)': {
                                                'gridTemplateColumns': 'repeat(1, 1fr)'
                                            }
                                        }}
                                    >
                                        {
                                            products.map((product, i) => (
                                                <ProductCard
                                                    key={i}
                                                    product={product}
                                                    imageLoading={imageLoading}
                                                    setImageLoading={setImageLoading}
                                                    isCart={false}
                                                    viewType={alignment}

                                                />
                                            ))
                                        }
                                    </Box>
                                )
                            }
                        </Box>
                    </Box>
                )
            }

        </Box>
    )
}

export default Products