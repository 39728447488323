import { Box, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { tokens } from "../../../theme";


const LoginForm = ({ values, errors, touched, handleBlur, handleChange, handleSubmit, state }) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const isNonMobile = useMediaQuery("(min-width:650px)");
    const xlScreen = useMediaQuery(theme.breakpoints.up("xl"));


    return (
        <Box
            display="grid"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            gridAutoRows="auto"
            gap="20px"
            justifyContent="center"
            sx={{
                width: '80%',
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
        >
            <TextField
                size="small"
                fullWidth
                variant="filled"
                type="email"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                disabled={state}
                sx={{
                    gridColumn: 'span 4',
                    "& .MuiInputLabel-root.Mui-focused": { color: colors.greenAccent[400] }
                }}
            />
            <TextField
                size="small"
                fullWidth
                variant="filled"
                type="password"
                label="Password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                name="password"
                error={!!touched.password && !!errors.password}
                helperText={touched.password && errors.password}
                disabled={state}
                sx={{
                    gridColumn: 'span 4',
                    "& .MuiInputLabel-root.Mui-focused": { color: colors.greenAccent[400] }
                }}
            />
        </Box>
    )

}

export default LoginForm;