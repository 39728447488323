import { Box, Button, Typography, useTheme } from "@mui/material"
import { tokens } from "../../theme";
import { useEffect, useRef, useState } from "react";
import api from "../../data/axios-api";
import { endpoints } from "../../data/api";
import Loader from "../../components/Loader";
import Error from "../../components/error";
import AddUserAddress from "./add-user-address";
import UserAddressList from "./user-address-list";
import CustomSnackbar from "../global/custom-snackbar";

const UserAddress = ({ setOpenDialog }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const hasMounted = useRef(false);
    const [loading, setLoading] = useState(false);
    const [address, setAddress] = useState([]);
    const [error, setError] = useState('');
    const [addUserAddress, setAddUserAddress] = useState(false);
    const [data, setData] = useState({});

    const [snack, setSnack] = useState({
        open: false,
        severity: '',
        message: ''
    });

    useEffect(() => {
        if (!hasMounted.current) {
            setLoading(true);
            api.get(`${endpoints.user.address.get}`, {})
                .then(res => {
                    const add = res['data'];
                    setAddress(add)
                    setLoading(false)
                }).catch((err) => {
                    console.log(err);
                    setError(err);
                    setLoading(false)
                })
                .finally(() => {

                })
        }
        hasMounted.current = true;
    }, [addUserAddress])

    const adduseraddress = (params) => {
        console.log('event: ', params)
        setAddUserAddress(true);
        if(params.hasOwnProperty('mode')) {
            setData(params)
        } else {

            setData({ mode: 'new', address: '' })
        }
    }

    const SetAddAddress = () => {
        setAddUserAddress(false);
        hasMounted.current = false;
    }

    const handleHttpState = (e) => {
        console.log(e)
        setSnack((prevsnack) => ({
            ...prevsnack,
            open: true,
            severity: 'success',
            message: e.message
        }))
    }

    const handleSnack = () => {
        setSnack((prevsnack) => ({
            ...prevsnack,
            open: false
        }))
    }


    return (
        <Box
            sx={{
                width: '580px',
                p: '15px',
                backgroundColor: theme.palette.mode === 'dark' ? colors.primary[700] : ''
            }}
        >
            {
                loading ? (<Loader />) : error !== '' ? (<Error apiError={error} />) : (
                    <Box>
                        {
                            addUserAddress ? <AddUserAddress httpState={handleHttpState} data={data} SetAddAddress={SetAddAddress} setOpenDialog={setOpenDialog} /> : address.length && !addUserAddress ? (<UserAddressList address={address} adduseraddress={adduseraddress} setOpenDialog={setOpenDialog} />) :
                                (<Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '20px',
                                        p: '15px'
                                    }}
                                >
                                    <Typography variant="h4" color={colors.grey[400]}>
                                        No Address found. Add one
                                    </Typography>
                                    <Box
                                    width={'100%'}
                                    display={'flex'}
                                    justifyContent={'center'}
                                    gap={'10px'}
                                    >
                                        <Button variant="contained" onClick={() => setOpenDialog(false)}>Cancel</Button>
                                        <Button variant="contained" color="secondary" onClick={adduseraddress}>Add Address</Button>
                                    </Box>
                                </Box>)
                        }
                    </Box>
                )
            }
            <CustomSnackbar isOpen={snack.open} message={snack.message} severity={snack.severity} handleClose={handleSnack} />
        </Box>
    )
}


export default UserAddress;