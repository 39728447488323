const PROD = process.env.REACT_APP_MODE;
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const endpoints =
  PROD !== "dev"
    ? {
        baseUrl: BASE_URL,
        auth: {
          login: "login",
          status: "is_user_approved",
        },
        product: {
          price: "fetch_user_products",
          cart: {
            get: "cart",
            add: "add_to_cart",
            update: "update_cart_items",
            delete: "delete_cart_item",
            cart_address: "update_cart_addresses",
          },
          order: {
            confirm: "confirm_order",
            get: "orders_list",
          },
        },
        user: {
          address: {
            get: "fetch_account_addresses",
            add: "add_account_addresses",
            delete: "delete_acc_address",
            edit: "edit_account_address"
          },
        },
      }
    : {
        baseUrl: BASE_URL,
        auth: {
          login: "login",
          status: "is_user_approved",
        },
        product: {
          price: "fetch_user_products",
          cart: {
            get: "cart",
            add: "add_to_cart",
            update: "update_cart_items",
            delete: "delete_cart_item",
            cart_address: "update_cart_addresses",
          },
          order: {
            confirm: "confirm_order",
            get: "orders_list",
          },
        },
        user: {
          get: 'initUserDetails',
          address: {
            get: "fetch_account_addresses",
            add: "add_account_addresses",
            delete: "delete_acc_address",
            edit: "edit_account_address"
          },
        },
      };
