import { Box, Button, Card, TextField, Typography, useTheme } from "@mui/material";
import CurrencyFormatter from "../../utilities/currency-formatter";
import { tokens } from "../../theme";


import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import { CartContext } from "../cart/cart-context";
import { useContext } from "react";

const List = ({product, isCart}) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { getCartItemQuantity, increaseItemQty, decreaseItemQty, removeFromCart } = useContext(CartContext);

    const quantity = getCartItemQuantity(product.id);

    const decreaseQty = () => {
        
        decreaseItemQty(product.id);
        console.log(getCartItemQuantity(product.id))
        if(getCartItemQuantity(product.id).units === 0) {
            console.log(product)
            removeFromCart(product.id)
        }
    }

    return (
        <Card sx={{
            width: '100%',
            marginBottom: '0rem',
            backgroundColor: 'transparent'
        }}>
            <Box
                display={'flex'}
                flexDirection={'row'}
                gap={'15px'}
                width={'100%'}
                height={'100%'}
            >
                <Box
                    width={'180px'}
                    height={'100%'}
                    position={'relative'}

                >
                    <img src={product.img}
                        alt={product.img}
                        style={{
                            objectFit: 'cover',
                            width: '100%',
                            height: '100%',
                        }}
                    />
                </Box>
                <Box
                    p={'10px'}
                    height={'100%'}
                    width={'100%'}
                    position={'relative'}
                >
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        gap={'3px'}
                        height={'50%'}
                    >
                        <Typography variant="h3" >{product.n}</Typography>
                        <Typography variant="h5" color={colors.grey[200]}>{product.cat}</Typography>
                        <Typography variant="h2" >
                            {CurrencyFormatter(product.p, 'USD')}<span style={{ fontSize: '14px' }}>/unit</span>
                        </Typography>

                    </Box>
                    <Box
                        height={'50%'}
                        display={'flex'}
                        flexDirection={'row'}
                        gap={'10px'}
                        alignItems={'center'}
                    >
                        {/*  <Button variant="outlined" color="secondary" size="medium" >
                            Add to Cart
                        </Button> */}
                        {
                            quantity >= 1 ? (
                                <Box
                                    display={'flex'}
                                    flexDirection={'row'}
                                    gap={'5px'}
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                >
                                    <Button variant="outlined" color="secondary" size="medium" sx={{ height: '36px', minWidth: '36px' }} onClick={() => decreaseQty()}>
                                        <RemoveIcon />
                                    </Button>
                                    <TextField
                                        value={quantity}
                                        sx={{ width: '60px' }}
                                        size="small"
                                        inputProps={{ min: 1, max: product.in_stock, style: { textAlign: 'center' } }}
                                    />
                                    <Button variant="outlined" color="secondary" size="medium" sx={{ height: '36px', minWidth: '36px' }} onClick={() => increaseItemQty(product)}>
                                        <AddIcon />
                                    </Button>
                                    {
                                        isCart ? (
                                            <Button variant="outlined" color="error" size="medium" sx={{ height: '36px', minWidth: '36px' }} onClick={() => removeFromCart(product.id)}>
                                                <DeleteIcon />
                                            </Button>
                                        ) : null
                                    }
                                </Box>
                            ) : (
                                <Button variant="outlined" color="secondary" size="medium" sx={{ height: '36px' }} onClick={() => increaseItemQty(product)}>
                                    Add to Cart
                                </Button>
                            )
                        }

                    </Box>
                </Box>
            </Box>
        </Card>
    )
}

export default List;