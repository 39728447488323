import React, { useEffect, useRef, useState } from "react";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import { useTheme, Box, IconButton, Typography, MenuItem, Menu, Chip, Avatar } from "@mui/material";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
//import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import BroadcastOnHomeIcon from '@mui/icons-material/BroadcastOnHome';
//import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
//import SearchIcon from "@mui/icons-material/Search";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ViewListIcon from '@mui/icons-material/ViewList';
import LogoutIcon from '@mui/icons-material/Logout';
//css
import './topbar.css'
import { CartContext } from "../cart/cart-context";
import { Link, useNavigate } from "react-router-dom";
import api from "../../data/axios-api";
import { endpoints } from "../../data/api";

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const colorMode = useContext(ColorModeContext);
  const { cartItems } = useContext(CartContext);
  const { isCart } = useContext(CartContext);
  const navigate = useNavigate();

  const currentTheme = theme.palette.mode;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const hasMounted = useRef(false)
  const [user, setUser] = useState([])

  //console.log(cartItems)
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    localStorage.removeItem('token');
    navigate('/')
    window.location.replace('/')
  }

  const totalItems = () => {
    if(cartItems.length) {

      let total = 0;
      for(let i = 0;  i < cartItems.length; i++) {
        total += cartItems[i].units
      }
      //console.log(total)
      return total || 0
    }
  }

  useEffect(() => {
    if(!hasMounted.current) {
      api.get(`${endpoints.user.get}`)
      .then(res => {
        console.log(res);
        setUser(res.data)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {})
    }
    hasMounted.current = true;
  }, [])

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      p={2}
      position={'fixed'}
      width={'100%'}
      zIndex={2}
      backgroundColor={currentTheme === 'dark' ? colors.primary[600] : '#fcfcfc'}
      boxShadow={currentTheme === 'dark' ? '1px 1px 6px ' + colors.primary[600] : '1px -3px 6px ' + colors.primary[600]}
    >
      <Box
        display="flex"
        justifyContent={'center'}
        alignItems={'center'}
        gap={'10px'}
      >
        <Typography variant="h3" fontWeight={'bold'} color={colors.greenAccent[500]}>
          Distributor's Portal
        </Typography>
        {/*  <Box
          display="flex"
          backgroundColor={colors.primary[400]}
          p={0.2}
          borderRadius={1}
        >
          <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Search" />
          <IconButton type="button">
            <SearchIcon />
          </IconButton>
        </Box> */}
      </Box>
      <Box display="flex" gap={'5px'} alignItems={'center'}>
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (

            <LightModeOutlinedIcon />
          ) : (
            <DarkModeOutlinedIcon />
          )}
        </IconButton>
        <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton>
        {/* <Chip
          label="Cart"
          onClick={handleClick}
          deleteIcon={<ShoppingCartIcon />}
          variant="outlined"
        /> */}

        {
          !isCart ? (
            <Link to="cart">

              <Chip

                sx={{
                  padding: '0 3px',
                  '& .MuiChip-label':
                  {
                    fontSize: '15px',
                  }
                }} icon={<ShoppingCartIcon />} label={totalItems()} variant="outlined" color="secondary" />
            </Link>
          ) : null
        }

        <Chip onClick={handleClick} avatar={<Avatar>{user ? user.DisplayName?.charAt(0).toUpperCase(): 'U'}</Avatar>} label={user.DisplayName} variant="outline" />
      </Box>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          style: {
            width: '160px'
          }
        }}
        disableScrollLock={true}
        sx={{
          mt: '10px'
        }}
      >
        <p style={{margin: '0', marginBottom: '10px',  padding: '5px', paddingTop: '0', display: 'flex', justifyContent: 'center', borderBottom: `1px solid ${colors.grey[400]}`}} >{user?.UserEmail}</p>
        <MenuItem onClick={handleClose} className="topbar-user-dropdown">
          <Link to="/" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', gap: '15px' }}>
            <BroadcastOnHomeIcon /> Products
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClose} className="topbar-user-dropdown">
          <Link to="/orders" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', gap: '15px' }}>
            <ViewListIcon /> Orders
          </Link>
        </MenuItem>
         <MenuItem onClick={handleClose} className="topbar-user-dropdown"
        >
          <Link to="/account" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', gap: '15px' }}>
          <PersonOutlinedIcon />
            Account
          </Link>
        </MenuItem>
        <MenuItem onClick={(e) => {
          handleClose(e)
          logout()
        }} className="topbar-user-dropdown">
          <LogoutIcon /> Logout
        </MenuItem>
      </Menu>

    </Box>

  );


};

export default Topbar;
