import { ColorModeContext, useMode } from "./theme";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route, Outlet } from "react-router-dom";

import ProtectedRoutes from "./auth/protectRoutes";
import Login from "./pages/global/login/login";
import Products from "./pages/products";
import Cart from "./pages/cart";
import Topbar from "./pages/global/Topbar";
import { CartProvider } from "./pages/cart/cart-context";
import OrderConfirmation from "./pages/order-confirmation/order-confirmation";
import OrderList from "./pages/orders/order-list";
import OrderDetails from "./pages/orders/order-details";
import OrderConfirmed from "./pages/global/order-confirmed";
import Accounts from "./pages/accounts";


const App = () => {
  const [theme, colorMode] = useMode();
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div style={{ height: "100%", width: "100%" }}>
          <main>
            <Routes>
              {/* <Route path="/" element={<ProtectedContent />} /> */}
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<ProtectedContent />}>
                <Route path="/" element={<Products />} index />
                <Route path="cart" element={<Cart />} />
                <Route path="order_confirmation/:cart_id" element={<OrderConfirmation />} />
                <Route path="orders" element={<OrderList />} />
                <Route path="order/:id" element={<OrderDetails />} />
                <Route path="confirmed" element={<OrderConfirmed />} />
                <Route path="account" element={<Accounts />} />
              </Route>
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

const ProtectedContent = () => {

  return (
    <Box>
      <CartProvider>
        <Topbar/>
        <ProtectedRoutes>
          <Outlet />
        </ProtectedRoutes>
      </CartProvider>
    </Box>
  );
};

export default App;
