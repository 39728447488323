import { Box, Button, Chip, List, ListItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import CurrencyFormatter from "../../utilities/currency-formatter";
import Address from "../global/address";

const OrderDetails = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [animate, setAnimate] = useState(false)

    const location = useLocation();
    const state = location.state;
    console.log(state)

    return (
        <Box
            width={'100%'}
            pt={'6rem'}
            display={'flex'}
            justifyContent={'center'}
        >
            <Box
                width={'80%'}
            >
                <Button
                    variant="clear"
                    size="small"
                    color="secondary"
                    style={{ gap: '5px' }}
                    onMouseEnter={() => setAnimate(true)}
                    onMouseLeave={() => setAnimate(false)}
                    component={Link}
                    to="/orders"
                    sx={{
                        '&:hover': {
                            backgroundColor: 'transparent',
                        },
                    }}
                >
                    <KeyboardBackspaceIcon />
                    <span className={`animated-button ${animate ? 'slide-in visible' : ''}`}>Orders</span>
                </Button>

                <Box
                    pt={'1rem'}
                    pl={'1rem'}
                >
                    <Typography variant="h3" color={colors.blueAccent[500]}>
                        Order details
                    </Typography>
                    <Box
                        pt={'2rem'}
                    >
                        <List>
                            <ListItem>
                                <strong>Details</strong>
                            </ListItem>
                            <ListItem>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{ borderBottom: 'none', pl: '0', fontWeight: 600 }}>Order date</TableCell>
                                            <TableCell sx={{ borderBottom: 'none', pl: '0', color: `${colors.grey[300]}`, borderRight: `1px solid ${colors.grey[200]}` }}>{state.date}, {state.time}</TableCell>
                                            <TableCell sx={{ borderBottom: 'none', pl: '0', fontWeight: 600, paddingLeft: '15px' }}>Confirm status</TableCell>
                                            <TableCell sx={{ borderBottom: 'none', pl: '0', color: `${colors.grey[300]}` }}>
                                                {state.isConfirmed ? 'Confirmed' : 'Not Confirmed'}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ borderBottom: 'none', pl: '0', fontWeight: 600 }}>Payment status</TableCell>
                                            <TableCell sx={{ borderBottom: 'none', pl: '0', color: `${colors.grey[300]}`, borderRight: `1px solid ${colors.grey[200]}` }}>
                                                {state.isPaid ? 'PAID' : 'NOT PAID'}
                                            </TableCell>
                                            <TableCell sx={{ borderBottom: 'none', pl: '0', fontWeight: 600, paddingLeft: '15px' }}>Processed</TableCell>
                                            <TableCell sx={{ borderBottom: 'none', pl: '0', color: `${colors.grey[300]}` }}>
                                                {state.isProcessed ? 'Processed' : 'Not processed'}
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell sx={{ borderBottom: 'none', pl: '0', fontWeight: 600, }}>Shipping status</TableCell>
                                            <TableCell sx={{ borderBottom: 'none', pl: '0', color: `${colors.grey[300]}`, borderRight: `1px solid ${colors.grey[200]}` }}>
                                                {state.isShipped ? 'Shipped' : 'Not Shipped'}
                                            </TableCell>
                                            <TableCell sx={{ borderBottom: 'none', pl: '0', fontWeight: 600, paddingLeft: '15px' }}>Invoice</TableCell>
                                            <TableCell sx={{ borderBottom: 'none', pl: '0', color: `${colors.grey[300]}` }}>
                                                {state.invoice_no === '' ? 'na' : state.invoice_no}
                                            </TableCell>
                                        </TableRow>

                                    </TableBody>
                                </Table>
                            </ListItem>
                            <ListItem>
                                <strong>Items ordered</strong>
                            </ListItem>
                            <TableContainer sx={{border: `1px solid ${colors.grey[300]}`, borderRadius: '5px', mt: '1rem', mb: '1rem', width: '98%', ml: '15px'}}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Category</TableCell>
                                            <TableCell>Price/unit</TableCell>
                                            <TableCell>Quantity</TableCell>
                                            <TableCell>Total</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            state.items_ordered.map((item, i) => (
                                                <TableRow key={item.id}>
                                                    <TableCell>{item.n}</TableCell>
                                                    <TableCell>{item.cat}</TableCell>
                                                    <TableCell>{CurrencyFormatter(item.p, 'USD')}</TableCell>
                                                    <TableCell>{item.units}</TableCell>
                                                    <TableCell>{CurrencyFormatter((item.p * item.units), 'USD')}</TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <ListItem sx={{ paddingTop: '1rem' }}>
                                <strong>Shipping & invoice Address</strong>
                            </ListItem>
                            <ListItem sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <Address address={state.shipping_addr} label={'Shipping address'} />
                            </ListItem>
                            <ListItem sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                               <Address address={state.invoice_addr} label={'Invoice Adddress'} />
                            </ListItem>
                        </List>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}


export default OrderDetails;