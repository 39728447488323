import { Box, Button, Checkbox, FormControlLabel, FormGroup, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Radio, TextField, Typography, useTheme } from "@mui/material"
import { useEffect, useRef, useState } from "react";
import { tokens } from "../../theme";
import api from "../../data/axios-api";


import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { Oval } from "react-loader-spinner";
import { endpoints } from "../../data/api";
import CustomSnackbar from "../global/custom-snackbar";
import { useNavigate } from "react-router-dom";
import RandomStringGenerator from "../../utilities/randon-string-generator";
import CustomDialog from "../global/custom-dialog";
import UserConfirmation from "../global/user-confirmation";

const UserAddressList = ({ address, adduseraddress, setOpenDialog }) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode)

    const navigate = useNavigate()

    const [checked, setChecked] = useState(false);
    const [selectedDelAddres, SetSelectedDelAddress] = useState(null);
    const [selectedInvAddres, SetSelectedInvAddress] = useState(null);
    const [loading, setLoading] = useState(false);
    const [consent, setConsent] = useState(false);
    const [openDlg, setOpenDlg] = useState(false);
    const [mode, setMode] = useState('new');
    const [invoiceAddress, setInvoiceAddress] = useState(address.filter(add => add.type === 'invoice'))
    const [deliveryAddress, setDeliveryAddress] = useState(address.filter(add => add.type === 'shipping'))
    const [isDelAdd, setIsDelAdd] = useState(false);
    const [isInvAdd, setIsInvAdd] = useState(false);


    const [snack, setSnack] = useState({
        open: false,
        severity: '',
        message: ''
    });
    const [formData, setFormData] = useState({
        uname: '',
        addr: '',
        ph: '',
        zip: ''
    })

    const [aid, setAid] = useState('');


    const closeAddressDlg = () => {
        setOpenDialog(false)
    }

    const handleSubmit = (e) => {
        const params = {
            shipping_addr: selectedDelAddres,
            invoice_addr: selectedInvAddres
        }
        setLoading(true)
        api.post(`${endpoints.product.cart.cart_address}`, params, {})
            .then(res => {
                setLoading(false)
                setSnack((prevsnack) => ({
                    ...prevsnack,
                    open: true,
                    message: res['data']['message'],
                    severity: 'success'
                }));
                const rs = RandomStringGenerator()
                navigate(`/order_confirmation/cart_id=${rs}`, { replace: true })
            }).catch(err => {
                console.log(err);
                setSnack((prevsnack) => ({
                    ...prevsnack,
                    open: true,
                    message: err['message'],
                    severity: 'error'
                }))
                setLoading(false)
            }).finally(() => {
                setTimeout(() => {
                    setSnack((prevsnack) => ({
                        ...prevsnack,
                        open: false
                    }))
                }, 3000)
            })

    }

    useEffect(() => {
        handleSameClicked(checked);
    }, [selectedDelAddres, selectedInvAddres, address])


    const handleDeliveryAddressSelect = (id) => {
        console.log(id)
        const selAddress = deliveryAddress.filter(add => add.address_id === id)
        //console.log(selAddress)
        SetSelectedDelAddress(selAddress[0]);
        setIsDelAdd(true);
    }

    const handleInvoiceAddressSelect = (id) => {
        console.log(id)
        const selAddress = invoiceAddress.filter(add => add.address_id === id)
        //console.log(selAddress)
        SetSelectedInvAddress(selAddress[0]);
        setIsInvAdd(true)
    }



    const handleSameClicked = (checked) => {
        //console.log(checked)
        setChecked(checked)
        if (checked) {
            setFormData(prevFormData => ({
                ...prevFormData,
                uname: selectedDelAddres.uname,
                ph: selectedDelAddres.ph,
                addr: selectedDelAddres.addr,
                zip: selectedDelAddres.zip,
                address_id: selectedDelAddres.address_id,
                type: selectedDelAddres.type
            }));
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                uname: '',
                ph: '',
                addr: '',
                zip: '',
                address_id: '',
                type: ''

            }));
        }
    }

    const editAddress = (address) => {
        //console.log(address)
        setMode('edit')
        
        const params = {
            mode: 'edit',
            address: address
        }
        //console.log('edit: ', params)

        adduseraddress(params)
    }

    const addNewAddress = () => {
        setMode('new')
        //console.log('new: ', mode)
        const params = {
            mode: 'new',
            address: ''
        }

        adduseraddress(params)
    }

    const confirmUser = (address_id) => {
        //setLoading(true)
        console.log(address_id);
        setAid(address_id)
        setOpenDlg(true)
    }

    const userConsent = (value) => {
        console.log(value);
        if (!value) {
            setOpenDlg(false)
        } else if (value) {
            deleteAdd()
        }
        setConsent(value)
    }

    const deleteAdd = () => {
        setOpenDlg(false);
        api.post(`${endpoints.user.address.delete}`, { address_id: aid })
            .then(res => {
                //setLoading(false);
                const deleObj = address.filter(add => add.address_id === aid)[0]
                if (deleObj['type'] === 'invoice') {
                    const updatedAdd = invoiceAddress.filter(ca => ca.address_id !== aid);
                    setInvoiceAddress(updatedAdd)
                } else if (deleObj['type'] === 'shipping') {
                    const updatedAdd = deliveryAddress.filter(ca => ca.address_id !== aid);
                    setDeliveryAddress(updatedAdd)
                }

                setSnack((prevsnack) => ({
                    ...prevsnack,
                    open: true,
                    message: res.data.message,
                    severity: 'success'
                }))

            })
            .catch((err) => {
                console.log(err);
                //setLoading(false)
                setSnack((prevsnack) => ({
                    ...prevsnack,
                    open: true,
                    message: 'Failed to delete address!!',
                    severity: 'error'
                }))
            })
            .finally(() => { })
    }



    return (
        <Box>
            <Box
                borderBottom={`1px solid ${colors.grey[400]}`}
                ml={'30px'}
                pb={'5px'}
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
            >
                <Typography variant="h3" color={'secondary'} fontWeight={'500'} display={'flex'} flexDirection={'column'} gap={'4px'}>
                    Shipping Address List
                    <span style={{ fontSize: '12px', color: colors.grey[500] }}>Please select shippping address.</span>
                </Typography>
                <Button variant="outlined" color="secondary" onClick={addNewAddress} disabled={loading}>
                    <AddIcon /> New Address
                </Button>
            </Box>
            <List>
                {
                    deliveryAddress.length ? (
                        deliveryAddress.map((add, i) => (
                            <ListItem key={i} onClick={() => handleDeliveryAddressSelect(add.address_id)}>
                                <ListItemButton role={undefined}>
                                    <ListItemIcon>
                                        <Radio
                                            edge={'start'}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': add.address_id }}
                                            checked={selectedDelAddres?.address_id === add.address_id}
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: colors.greenAccent[500]
                                                }
                                            }}
                                            name="shipping"
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={add.address_id} primary={'Attendant: ' + add.attn + ', Company: ' + add.uname + ', ' + add.addr + ', ' + add.zip} />
                                    <IconButton
                                        color="secondary"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            editAddress(add)
                                        }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton color="error" onClick={(e) => {
                                        e.stopPropagation()
                                        confirmUser(add.address_id)
                                    }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemButton>
                            </ListItem>
                        ))
                    ) : (
                        <Box
                            width={'100%'}
                            p={'10px'}
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                        >
                            <Typography variant="h5" color={colors.grey[300]} > No shipping address found, add one.</Typography>
                        </Box>)
                }
            </List>
            <Box
                borderBottom={`1px solid ${colors.grey[400]}`}
                ml={'30px'}
                pb={'5px'}
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
                mb={'20px'}
            >
                <Typography variant="h3" color={'secondary'} fontWeight={'500'} display={'flex'} flexDirection={'column'} gap={'4px'}>
                    Invoice Address
                    <span style={{ fontSize: '12px', color: colors.grey[500] }}>Please select Invoice address</span>
                </Typography>
                {/* <FormGroup>
                    <FormControlLabel control={<Checkbox sx={{
                        '&.Mui-checked': {
                            color: colors.greenAccent[500]
                        }
                    }}
                        onChange={(e) => handleSameClicked(e.target.checked)}
                        disabled={loading}
                    />
                    } label="Same as Shipping" disabled={!selectedAddres} />
                </FormGroup> */}
            </Box>
            <Box

                pb={'25px'}
                width={'100%'}
            >
                <List>
                    {
                        invoiceAddress.length ? (

                            invoiceAddress.map((add, i) => (
                                <ListItem key={i} onClick={() => handleInvoiceAddressSelect(add.address_id)}>
                                    <ListItemButton role={undefined}>
                                        <ListItemIcon>
                                            <Radio
                                                edge={'start'}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': add.address_id }}
                                                checked={selectedInvAddres?.address_id === add.address_id}
                                                sx={{
                                                    '&.Mui-checked': {
                                                        color: colors.greenAccent[500]
                                                    }
                                                }}
                                                name="invoice"
                                            />
                                        </ListItemIcon>
                                        <ListItemText id={add.address_id} primary={'Attendant: ' + add.attn + ', Company: ' + add.uname + ', ' + add.addr + ', ' + add.zip} />
                                        <IconButton
                                            color="secondary"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                editAddress(add)
                                            }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton color="error" onClick={(e) => {
                                            e.stopPropagation()
                                            confirmUser(add.address_id)
                                        }}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </ListItemButton>
                                </ListItem>
                            ))
                        ) : (
                            <Box
                                width={'100%'}
                                p={'10px'}
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems={'center'}
                            >
                                <Typography variant="h5" color={colors.grey[300]} > No Invoice address found, add one.</Typography>
                            </Box>
                        )
                    }
                </List>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '10px'
                    }}
                >

                    <Button variant="contained" onClick={closeAddressDlg} disabled={loading}>
                        Cancel
                    </Button>
                    {
                        !loading ? (
                            <Button variant="outlined" color="secondary" onClick={handleSubmit} type="submit" disabled={(!isDelAdd || !isInvAdd)}>
                                Save & Continue <ArrowForwardIcon />
                            </Button>
                        ) : (
                            <Button variant="outlined" color="secondary" disabled={true} sx={{ display: 'flex', gap: '5px', flexDirection: 'row' }}>
                                <Oval
                                    height={20}
                                    width={20}
                                >

                                </Oval>
                                Saving...
                            </Button>
                        )
                    }

                </Box>

                {/* <form onSubmit={(e) => {
                    e.preventDefault();
                    handleForm(e)

                }}
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '15px'
                    }}
                >
                    <TextField
                        id="uname"
                        label="Name"
                        variant="filled"
                        value={formData.uname}
                        onChange={handleChange}
                        error={formErrors.uname !== ''}
                        helperText={formErrors.uname}
                        disabled={loading}

                    />
                    <TextField
                        id="ph"
                        label="Phone number"
                        variant="filled"
                        value={formData.ph}
                        onChange={handleChange}
                        error={formErrors.ph !== ''}
                        helperText={formErrors.ph}
                        disabled={loading}
                    />
                    <TextField
                        id="addr"
                        label="Address"
                        variant="filled"
                        value={formData.addr}
                        onChange={handleChange}
                        error={formErrors.addr !== ''}
                        helperText={formErrors.addr}
                        disabled={loading}
                    />
                    <TextField
                        id="zip"
                        label="Zip Code"
                        variant="filled"
                        value={formData.zip}
                        onChange={handleChange}
                        error={formErrors.zip !== ''}
                        helperText={formErrors.zip}
                        disabled={loading}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            gap: '10px'
                        }}
                    >

                        <Button variant="contained" onClick={closeAddressDlg} disabled={loading}>
                            Cancel
                        </Button>
                        {
                            !loading ? (
                                <Button variant="outlined" color="secondary" type="submit" disabled={!isFormValid()}>
                                    Save & Continue <ArrowForwardIcon />
                                </Button>
                            ) : (
                                <Button variant="outlined" color="secondary" disabled={true} sx={{ display: 'flex', gap: '5px', flexDirection: 'row' }}>
                                    <Oval
                                        height={20}
                                        width={20}
                                    >

                                    </Oval>
                                    Saving...
                                </Button>
                            )
                        }

                    </Box>
                </form> */}
            </Box>
            <CustomSnackbar
                isOpen={snack.open}
                severity={snack.severity}
                message={snack.message}
            />
            <CustomDialog open={openDlg} component={UserConfirmation} setConsent={userConsent} />
        </Box>

    )
}

export default UserAddressList;