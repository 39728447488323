import { Box, Button, Typography, useTheme } from "@mui/material"
import { Link } from "react-router-dom";
import { tokens } from "../../theme";
import './cart.css';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useContext, useEffect, useRef, useState } from "react";
import { CartContext } from "./cart-context";
import EmptyCart from '../../assets/images/cart.svg';
import CartItemList from "./cart-item-list";
import Loader from "../../components/Loader";
import OrderSummary from "./order-summary";
import ProductNavigateButton from "../global/product-navigate-button";

const Cart = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { cartItems, toggleCart, loading } = useContext(CartContext);
    const [animate, setAnimate] = useState(false);
    const hasMounted = useRef(false);
    const [totalcost, setTotalcost] = useState(0);

//console.log('React rerendering...', cartItems)

    useEffect(() => {
        toggleCart(true)
        totalCost();
    }, [cartItems]);

    const totalCost = () => {
        let totalcost = 0;
        for (let i = 0; i < cartItems.length; i++) {
            //console.log('cartItem: ', cartItems[i])
            totalcost += cartItems[i].p * cartItems[i].units
        }

        setTotalcost(totalcost)
    }

    return (
        <Box
            display={'flex'}
            width={'100%'}
            position={'relative'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            paddingTop={'80px'}
        >
            <Box
                width={'80%'}

            >
                <ProductNavigateButton />
                <Box
                    mt={'1rem'}
                    width={'100%'}
                >

                    {/* card begins */}
                    <Box
                        display={'flex'}
                        justifyContent={cartItems.length ? 'space-evenly' : 'center'}
                        flexDirection={cartItems.length ? '' : 'column'}
                        alignItems={cartItems.length ? '' : 'center'}
                    >
                        <Box
                            width={'100%'}
                            display={'flex'}
                            flexDirection={'row'}
                            gap={'30px'}
                        >
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                width={cartItems.length ? '70%':'100%'}
                            >
                                <Typography variant="h3" color={colors.grey[500]} marginBottom={'1rem'}>
                                    Cart ({cartItems.length})
                                </Typography>


                                {
                                    loading ? (<Loader />) : cartItems.length ? (
                                        cartItems.map((item, i) => (
                                            //<ProductCard key={i} product={item} imageLoading={imageLoading} setImageLoading={setImageLoading} isCart={true} viewType={'list'} />
                                            <CartItemList key={i} item={item} />
                                        ))
                                    ) : (
                                        <Box
                                            height={'60vh'}
                                            display={'flex'}
                                            justifyContent={'center'}
                                            alignItems={'center'}
                                            flexDirection={'column'}
                                            gap={'1rem'}
                                        >
                                            <img src={EmptyCart} alt="empty_cart" width={'228'} />
                                            <Typography variant="h4" color={colors.grey['600']} fontWeight={600}>
                                                Cart is empty...
                                            </Typography>
                                        </Box>
                                    )

                                }
                            </Box>
                            {
                                cartItems.length ? (
                                    <Box
                                        display={'flex'}
                                        flexDirection={'column'}
                                        width={`calc(30% - 30px)`}
                                    >
                                        <Typography variant="h3" color={colors.grey[500]} marginBottom={'1rem'}>
                                            Order Summary
                                        </Typography>
                                        <OrderSummary cartItems={cartItems} />
                                    </Box>
                                ) : ''
                            }
                        </Box>
                    </Box> {/* cart cards */}
                </Box>
            </Box>
        </Box>
    )
}

export default Cart;

