import axios from "axios";
import { endpoints } from "./api";
import { useNavigate } from "react-router-dom";


const api = axios.create({
    baseURL: endpoints.baseUrl
});

api.interceptors.request.use((config) => {
    if(config.url !== '/login') {
        config.headers.Authorization = 'Bearer ' + localStorage.getItem('token');
    }

    return config;
}, err => {
    return Promise.reject(err)
});


api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401 && error.config.url !== 'login') {
            //console.log(error.config.url)
            window.location.replace('/login')
        }
        return Promise.reject(error);
    }
);

export default api;