import { Box, Tab, Tabs, Typography, useTheme } from "@mui/material"
import { tokens } from "../../theme";


//icons
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { useContext, useEffect, useRef, useState } from "react";
import { TabPanel } from "@mui/lab";
import Address from "./address";
import ProductNavigateButton from "../global/product-navigate-button";
import { CartContext } from "../cart/cart-context";


const Accounts = () => {
    
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [tab, setTab] = useState('address')
    const { toggleCart } = useContext(CartContext);
    const hasMounted = useRef(false)

    const handleChange = (event, tabChange) => {
        //console.log(event)
        setTab(tabChange)
    }

    useEffect(() => {
        toggleCart(false)
    },[])

    return (
        <Box
            display={'flex'}
            justifyContent={'center'}
            width={'100%'}
            position={'relative'}
            gap={'10px'}
            pl={'4rem'}
            pr={'4rem'}
            pt={'8rem'}
            height={'100vh'}
        >
            <Box
                width={'80%'}
                display={'flex'}
                justifyContent={'flex-start'}
                flexDirection={'column'}
            >
                <ProductNavigateButton />
                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    gap={'10px'}
                    alignItems={'center'}
                    pt={'1rem'}
                >
                   {/*  <PersonOutlinedIcon sx={{ fontSize: '35px', color: `${colors.blueAccent[500]}` }} /> */}
                    <Typography variant="h3" color={colors.blueAccent[500]}>
                        Accounts Settings
                    </Typography>
                </Box>
                <Box
                    width={'100%'}
                    pt={'1rem'}
                >
                    <Tabs
                        value={tab}
                        onChange={handleChange}
                        textColor="secondary"
                        indicatorColor="secondary"
                        aria-label="secondary tabs example"
                        centered
                    >
                        <Tab value="address" label="Manage Address" />

                        <Tab value="pinfo" label="Personal Information" disabled />
                        <Tab value="settings" label="Settings" disabled />
                    </Tabs>
                    <Box

                    >
                        {tab === 'address' && (
                            <Address />
                        )
                        }
                        {tab === 'pinfo' && (<Box>
                            Personal info
                        </Box>)
                        }
                        {tab === 'settings' && (<Box>
                            settings
                        </Box>)
                        }
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Accounts