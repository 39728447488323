import { Box} from "@mui/material";
import List from "./list";
import Grid from "./grid";



const ProductCard = ({ product, imageLoading, setImageLoading, isCart, viewType }) => {

    return (
        <Box
            width={'100%'}
            height={viewType === 'list' ? '220px' : 'auto'}
        >
            <Box
                width={'100%'}
                height={'100%'}
                display={'flex'}
                justifyContent={'center'}
                padding={'5px'}
            >
                {viewType === 'list' ? (
                    <List product={product} isCart={isCart} />

                ) : (
                    <Grid product={product} isCart={isCart} />
                )}
            </Box>
        </Box>
    )
}

export default ProductCard