import { Avatar, Box, Button, Card, List, ListItem, ListItemAvatar, ListItemText, Typography, useTheme } from "@mui/material";
import { tokens } from '../../theme'
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import api from "../../data/axios-api";
import { endpoints } from "../../data/api";
import CurrencyFormatter from "../../utilities/currency-formatter";
import Loader from "../../components/Loader";
import Error from "../../components/error";
import { useNavigate } from "react-router-dom";
import CustomSnackbar from "../global/custom-snackbar";
import { CartContext } from "../cart/cart-context";

const OrderConfirmation = () => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode);
    const hasMounted = useRef(false);
    const [isLoading, setIsLoadong] = useState(false);
    const [cartItems, setCartItems] = useState({});
    const [oderItems, setOrderItems] = useState([]);
    const [error, setError] = useState('');
    const [totalCost, setTotalCost] = useState(0);
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");

    const { clearCart } = useContext(CartContext);


    useEffect(() => {
        if (!hasMounted.current) {
            setIsLoadong(true);
            api.get(`${endpoints.product.cart.get}`, {})
                .then(res => {
                    const data = res['data'];
                    setCartItems((prev) => data);
                    setOrderItems(data['order_info'])
                    setIsLoadong(false);
                    calculatePrice(data['order_info'])
                })
                .catch(err => {
                    console.log(err);
                    setError(err);
                    setIsLoadong(false)
                })
                .finally(() => {

                })

            const calculatePrice = (order) => {
                console.log('calculating price', order)
                let totalcost = 0;
                for (let i = 0; i < order.length; i++) {
                    totalcost += order[i].p * order[i].units;
                }

                setTotalCost(totalcost)
            };
        }

        hasMounted.current = true;

    }, [oderItems])

    const confirmOrder = () => {
        setIsLoadong(true);
        const ndate = new Date()
        const date = ndate.getDate() + '/' + (ndate.getMonth() + 1) + '/' + ndate.getFullYear();
        const time = ndate.getHours() + ':' + ndate.getMinutes()
        console.log(time)
        const params = {
            date: date,
            time: time,
            is_confirmed: true
        }
        api.post(`${endpoints.product.order.confirm}`, params)
            .then(res => {
                console.log(res);
                setIsLoadong(false)
                setMessage(res['data']['message']);
                setIsOpen(true);
                setSeverity('success')
                navigate('/confirmed')
                setCartItems([]);
                clearCart()
            })
            .catch(err => {
                console.log(err);
                setIsLoadong(false)
                setMessage('Failed to confirm order');
                setIsOpen(true);
                setSeverity('error')
            })
            .finally(() => { })
    }

    const cart = () => {
        navigate('/cart')
    }




    return (
        <Box
            width={'100%'}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            paddingTop={'80px'}
        >

            <Box
                width={'80%'}
            >
                <Typography variant="h4" color={colors.grey[400]} mb={'1.3rem'}>
                    Oder Confirmation
                </Typography>

                {
                    isLoading ? (<Loader />) : error !== '' ? (
                        <Box
                        width={'100%'}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        gap={'1.4rem'}
                        >
                            <Error apiError={error} />
                            <Button variant="outlined" color="secondary" onClick={() => navigate('/')} >Products</Button>
                        </Box>
                    ) :
                        (
                            <>
                                <List
                                    sx={{
                                        display: 'flex',
                                        gap: '5px',
                                        flexDirection: 'column'
                                    }}
                                >
                                    <ListItem>
                                        <ListItemText primary={'Item'}></ListItemText>
                                        <Box
                                            width={'50%'}
                                            display={'flex'}
                                            flexDirection={'row'}
                                            justifyContent={'center'}
                                        >
                                            <Box sx={{ width: '50%', display: 'flex', justifyContent: 'center' }}>
                                                Quantity
                                            </Box>
                                            <Box sx={{ width: '50%', display: 'flex', justifyContent: 'center' }}>
                                                Price/unit
                                            </Box>
                                            <Box sx={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
                                                Subtotal
                                            </Box>
                                        </Box>
                                    </ListItem>
                                    {
                                        oderItems.map((item, i) => (
                                            <Card
                                                sx={{
                                                    backgroundColor: 'transparent'
                                                }}
                                                key={i}
                                            >

                                                <ListItem>
                                                    <ListItemAvatar>
                                                        <Avatar src={item.img} />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={item.n}
                                                        secondary={
                                                            <Fragment>
                                                                <Typography variant="body1" component="span" color={colors.grey[400]}>
                                                                    {item.cat}
                                                                </Typography>
                                                            </Fragment>
                                                        }
                                                    />
                                                    <Box
                                                        width={'50%'}
                                                        display={'flex'}
                                                        flexDirection={'row'}
                                                        justifyContent={'center'}
                                                    >
                                                        <Box sx={{ width: '50%', display: 'flex', justifyContent: 'center' }}>
                                                            {item.units}
                                                        </Box>
                                                        <Box sx={{ width: '50%', display: 'flex', justifyContent: 'center' }}>
                                                            {CurrencyFormatter(item.p, 'USD')}
                                                        </Box>
                                                        <Box sx={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }} >
                                                            {CurrencyFormatter((item.units * item.p), 'USD')}
                                                        </Box>
                                                    </Box>
                                                </ListItem>
                                            </Card>
                                        ))

                                    }
                                    <ListItem >
                                        <ListItemText primary={''}></ListItemText>
                                        <Box
                                            width={'50%'}
                                            display={'flex'}
                                            flexDirection={'row'}
                                            justifyContent={'center'}
                                        >
                                            <Box sx={{ width: '50%', display: 'flex', justifyContent: 'center', fontWeight: '600' }}>
                                                Total
                                            </Box>
                                            <Box sx={{ width: '50%', display: 'flex', justifyContent: 'center', fontWeight: '600' }}>

                                            </Box>
                                            <Box sx={{ width: '50%', display: 'flex', justifyContent: 'flex-end', fontWeight: '600' }}>
                                                {CurrencyFormatter(totalCost, 'USD')}
                                            </Box>
                                        </Box>
                                    </ListItem>
                                </List>
                                <Box>
                                    <Typography variant="h4" color={colors.grey[400]}>
                                        Shipping Address
                                    </Typography>
                                    <Box
                                        padding={'10px'}
                                        borderRadius={'5px'}
                                        //backgroundColor={colors.grey[700]}
                                        mt={'1rem'}
                                        position={'relative'}
                                        mb={'1rem'}
                                        sx={{
                                            '&::before': {
                                                content: '""',
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                bottom: 0,
                                                backgroundColor: colors.grey[700],
                                                opacity: '0.4',
                                                borderRadius: '5px'
                                            }
                                        }}
                                    >
                                        <span>
                                            {cartItems.shipping_addr?.uname} <br />
                                            {cartItems.shipping_addr?.addr + ',' + cartItems.shipping_addr?.zip}
                                        </span>
                                    </Box>
                                    <Typography variant="h4" color={colors.grey[400]} >
                                        Invoice Address
                                    </Typography>
                                    <Box
                                        padding={'10px'}
                                        borderRadius={'5px'}
                                        //backgroundColor={colors.grey[700]}
                                        mt={'1rem'}
                                        position={'relative'}
                                        sx={{
                                            '&::before': {
                                                content: '""',
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                bottom: 0,
                                                backgroundColor: colors.grey[700],
                                                opacity: '0.4',
                                                borderRadius: '5px'
                                            }
                                        }}
                                    >
                                        <span>
                                            {cartItems.invoice_addr?.uname} <br />
                                            {cartItems.invoice_addr?.addr + ',' + cartItems.invoice_addr?.zip}
                                        </span>
                                    </Box>
                                </Box>
                                <Box
                                    width={'100%'}
                                    display={'flex'}
                                    justifyContent={'flex-end'}
                                    gap={'10px'}
                                    mt={'1rem'}
                                >
                                    <Button variant="contained" color="info" sx={{
                                        width: '150px',
                                        height: '50px',
                                        fontWeight: 'bold'
                                    }}
                                        onClick={cart}
                                    >
                                        Go back to Cart
                                    </Button>
                                    <Button variant="contained" color="secondary" sx={{
                                        width: '150px',
                                        height: '50px',
                                        fontWeight: 'bold'
                                    }}
                                        onClick={confirmOrder}
                                    >
                                        Confirm order
                                    </Button>
                                </Box>
                            </>
                        )
                }

            </Box>
            <CustomSnackbar isOpen={isOpen} message={message} severity={severity} />
        </Box>
    )
}

export default OrderConfirmation;