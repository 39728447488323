import { Box, Button, Typography, useTheme } from "@mui/material"
import { tokens } from "../../theme";

const UserConfirmation = ({setConsent}) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const onConfirm = () => {
        setConsent(true)
    }

    const onCancel = () => {
        setConsent(false)
    }

    return (
        <Box
            width={'480px'}
            height={'200px'}
            display={"flex"}
            flexDirection={'column'}
            justifyContent={'center'}
            gap={'2rem'}
            alignItems={'center'}
            p={'15px'}
            sx={{
                backgroundColor: theme.palette.mode === 'dark' ? colors.primary[700] : ''
            }}
        >
            <Typography variant="h4">Are you sure you want to delete!!!</Typography>
            <Box
            width={'100%'}
            display={'flex'}
            justifyContent={'flex-end'}
            gap={'10px'}
            >
            <Button variant="contained" onClick={onCancel}>Cancel</Button>
            <Button variant="outlined" color="error" onClick={onConfirm}>Delete</Button>
            </Box>
        </Box>
    )
}

export default UserConfirmation