import { Box, Button, Card, CardActions, CardContent, CardMedia, TextField, Typography, useMediaQuery } from "@mui/material"
import { tokens } from "../../theme";
import { useTheme } from "@emotion/react";
import { useContext } from "react";
import { CartContext } from "../cart/cart-context";
import CurrencyFormatter from "../../utilities/currency-formatter";

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';


const Grid = ({ product, isCart }) => {

    const isNonMobile = useMediaQuery("(min-width:600px)");
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { getCartItemQuantity, increaseItemQty, decreaseItemQty, removeFromCart } = useContext(CartContext);

    const quantity = getCartItemQuantity(product.id);

    const decreaseQty = () => {

        decreaseItemQty(product.id);
        //console.log(getCartItemQuantity(product.id))
        if (getCartItemQuantity(product.id) === 1) {
            console.log(product)
            removeFromCart(product.id)
        }
        
    }


    return (
        <Card sx={{ maxWidth: 345, width: '220px' }}>
            <CardMedia
                sx={{ height: 140 }}
                image={product.img}
                title={product.n}
            />
            <CardContent>
                <Typography gutterBottom variant="h5" fontSize={'20px'} component="div" sx={{ height: '60px' }}>
                    {product.n}
                </Typography>
                <Typography variant="body2" fontSize={'1.2rem'} color="text.secondary">
                    Price: {CurrencyFormatter(product.p, 'USD')} / unit
                </Typography>
            </CardContent>
            <CardActions sx={{p: '1rem'}}>
                {
                    quantity >= 1 ? (
                        <Box
                            display={'flex'}
                            flexDirection={'row'}
                            gap={'5px'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            width={'100%'}
                        >
                            <Button variant="outlined" color="secondary" size="medium" sx={{ height: '36px', minWidth: '36px' }} onClick={() => decreaseQty()}>
                                <RemoveIcon />
                            </Button>
                            <TextField
                                value={quantity}
                                sx={{ width: '60px' }}
                                size="small"
                                inputProps={{ min: 1, max: product.in_stock, style: { textAlign: 'center' } }}
                            />
                            <Button variant="outlined" color="secondary" size="medium" sx={{ height: '36px', minWidth: '36px' }} onClick={() => increaseItemQty(product)}>
                                <AddIcon />
                            </Button>
                            {
                                isCart ? (
                                    <Button variant="outlined" color="error" size="medium" sx={{ height: '36px', minWidth: '36px' }} onClick={() => removeFromCart(product.id)}>
                                        <DeleteIcon />
                                    </Button>
                                ) : null
                            }
                        </Box>
                    ) : (

                        <Box
                            width={'100%'}
                            display={'flex'}
                            justifyContent={'flex-start'}
                            alignItems={'center'}
                        >
                            <Button size="small" sx={{minHeight: '36px', height: '36px'}} variant="outlined" color="secondary" onClick={() => increaseItemQty(product)}>Add to cart</Button>
                        </Box>
                    )
                }

            </CardActions>
        </Card>
    )
}

export default Grid