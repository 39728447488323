import { Box, Button, Typography, useTheme } from "@mui/material"
import { tokens } from "../../theme";

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useNavigate } from "react-router-dom";

const OrderConfirmed = () => {
    const theme = useTheme();
    const colors = tokens(theme);

    const navigate = useNavigate()

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            width="100%"
            height="100vh"
        >
            <Box
                width={'100%'}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                gap={'1rem'}
                mb={'2rem'}
            >
                <TaskAltIcon sx={{ fontSize: '128px' }} />
                <Typography color={colors.greenAccent[600]} variant="h2" fontWeight="bold" sx={{ marginTop: '1rem' }}>
                    Done 
                </Typography>
                <Typography color={colors.grey[600]} variant="h5" sx={{ marginTop: '0.2rem' }}>
                    Your order is successfuly placed.
                </Typography>
            </Box>
            <Box
                width={'100%'}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'center'}
                alignItems={'center'}
                gap={'20px'}
            >
                <Button variant="contained" color="primary" onClick={() => navigate('/')} >Go to products</Button>
                <Button variant="contained" color="secondary" onClick={() => navigate('/orders')}>Go to Orders</Button>
            </Box>
        </Box>
    )
}

export default OrderConfirmed