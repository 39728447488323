import { Box, FormControl, InputLabel, Select, MenuItem, TextField, useMediaQuery, useTheme } from "@mui/material";
import { tokens } from "../../theme";



const AddressForm = ({ values, errors, touched, handleBlur, handleChange, handleSubmit, state }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const isNonMobile = useMediaQuery("(min-width:650px)");
    //const xlScreen = useMediaQuery(theme.breakpoints.up("xl"));

    return (
        <Box
            display="grid"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            gridAutoRows="auto"
            gap="20px"
            justifyContent="center"
            sx={{
                width: '100%',
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
        >
            <TextField
                size="small"
                fullWidth
                variant="filled"
                type="text"
                label="Company Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.uname}
                name="uname"
                error={!!touched.uname && !!errors.uname}
                helperText={touched.uname && errors.uname}
                disabled={state}
                sx={{
                    gridColumn: 'span 4',
                    "& .MuiInputLabel-root.Mui-focused": { color: colors.greenAccent[400] }
                }}
            />
            <TextField
                size="small"
                fullWidth
                variant="filled"
                type="text"
                label="Attendant"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.attn}
                name="attn"
                error={!!touched.attn && !!errors.attn}
                helperText={touched.attn && errors.attn}
                disabled={state}
                sx={{
                    gridColumn: 'span 4',
                    "& .MuiInputLabel-root.Mui-focused": { color: colors.greenAccent[400] }
                }}
            />
            <TextField
                size="small"
                multiline
                fullWidth
                variant="filled"
                type="text"
                label="Address"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.addr}
                name="addr"
                error={!!touched.addr && !!errors.addr}
                helperText={touched.addr && errors.addr}
                disabled={state}
                sx={{
                    gridColumn: 'span 4',
                    "& .MuiInputLabel-root.Mui-focused": { color: colors.greenAccent[400] }
                }}
            />
            <TextField
                size="small"
                fullWidth
                variant="filled"
                type="text"
                label="Phone"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.ph}
                name="ph"
                error={!!touched.ph && !!errors.ph}
                helperText={touched.ph && errors.ph}
                disabled={state}
                sx={{
                    gridColumn: 'span 4',
                    "& .MuiInputLabel-root.Mui-focused": { color: colors.greenAccent[400] }
                }}
            />
            <TextField
                size="small"
                fullWidth
                variant="filled"
                type="text"
                label="ZIP Code"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.zip}
                name="zip"
                error={!!touched.zip && !!errors.zip}
                helperText={touched.zip && errors.zip}
                disabled={state}
                sx={{
                    gridColumn: 'span 4',
                    "& .MuiInputLabel-root.Mui-focused": { color: colors.greenAccent[400] }
                }}
            />
            <Box
                gridColumn="span 4"
            >
                <FormControl sx={{ width: '100%' }} size="small" variant="filled">
                    <InputLabel id="type">Address type</InputLabel>
                    <Select
                    id="type"
                        labelId="type"
                        value={values.type ?? ""}
                        name="type"
                        label="Address type"
                        onChange={handleChange}
                        disabled={state}
                    >
                       {/*  <MenuItem value="">
                            <em>None</em>
                        </MenuItem> */}
                        <MenuItem value='shipping'>Shipping</MenuItem>
                        <MenuItem value='invoice'>Invoice</MenuItem>
                    </Select>
                </FormControl>
            </Box>
        </Box>
    )

}

export default AddressForm