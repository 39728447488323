import { Box, Button, Card, List, ListItem, ListItemText, Typography, useTheme } from "@mui/material"
import { tokens } from "../../theme";
import { useEffect, useState } from "react";
import api from '../../data/axios-api';

//iocns
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import CustomDialog from "../global/custom-dialog";
import { endpoints } from "../../data/api";
import UserAddress from "../user/user-address";

const OrderSummary = ({ cartItems }) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [totalCost, setTotalCost] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState('');
    const [addresses, setAddresses] = useState([]);
    const [loading, setLoading] = useState(false);
    const component = UserAddress;

    useEffect(() => {
        calculatePrice()
    }, [totalCost, cartItems])


    const calculatePrice = () => {
        let totalcost = 0;
        for (let i = 0; i < cartItems.length; i++) {
            totalcost += cartItems[i].p * cartItems[i].units
        }

        setTotalCost(totalcost)
    };

    const checkAddressExits = () => {
        setOpenDialog(true)
    }

    const showDialog = () => {
        setOpenDialog(true)
    }

    return (
        <Card
            sx={{
                backgroundColor: 'transparent',
                padding: '15px'
            }}
        >
            <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'center'}
                alignItems={'space-between'}
                width={'100%'}
                borderBottom={`1px solid ${colors.grey[400]}`}
            >
                <Box
                    sx={{ width: '50%' }}
                    
                >
                    <Typography variant="h4" fontWeight={'700'}>
                        Item
                    </Typography>
                </Box>
                <Box
                    sx={{ width: '50%' }}
                    display={'flex'}
                    justifyContent={'flex-end'}
                >
                    <Typography variant="h4" fontWeight={'700'}>
                        Amount
                    </Typography>
                </Box>

            </Box>
            <List>
                {
                    cartItems.map((item, i) => (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                ':last-child': {
                                    borderBottom: `1px solid ${colors.grey[400]}`
                                }
                            }}
                            key={i}
                        >
                            <ListItem key={i} sx={{ paddingLeft: 0 }}>
                                <ListItemText primary={item.n} secondary={`Qty: ${item.units} | Price/unit: ${item.c + item.p}`} />
                            </ListItem>
                            <Box
                                display={'flex'}
                                gap={'3px'}
                                fontWeight={'600'}
                            >
                                <span>{item.c}</span> {item.units * item.p}
                            </Box>

                        </Box>

                    ))
                }
                <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    borderTop={`1px solid ${colors.grey[400]}`}
                    pt={'1rem'}
                >

                    <Typography variant="h3" fontWeight={'bold'}>
                        Total
                    </Typography>
                    <Typography variant="h3" fontWeight={'bold'}>
                        ${totalCost}
                    </Typography>
                </Box>
                <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    width={'100%'}
                    pt={'1rem'}
                >
                    <Button 
                    variant="contained" 
                    color="secondary" 
                    size="medium"  
                    startIcon={<ShoppingCartCheckoutIcon />}
                    sx={{
                        width: '100%',
                        fontWeight: '600',
                        fontSize: '18px'
                    }}
                    onClick={checkAddressExits}
                    >
                        Checkout
                    </Button>
                    <CustomDialog open={openDialog} isLoader={loading} setOpenDialog={setOpenDialog} component={component}  content={dialogContent}/>
                </Box>
            </List>
        </Card>
    )
}

export default OrderSummary;

