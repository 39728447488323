import { Box, Button, Link, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { tokens } from "../../../theme";
import * as yup from 'yup';
import { Formik } from "formik";
import LoginForm from "./login-form";
import { endpoints } from "../../../data/api";
import Loader from "../../../components/Loader";
import Footer from "../../footer";
import { useNavigate } from "react-router-dom";

//icons
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import api from "../../../data/axios-api";
import CustomSnackbar from "../custom-snackbar";


const Login = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const xlScreen = useMediaQuery(theme.breakpoints.up("xl"));

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const hasMounted = useRef(false)
    const [initialValues, setInitVal] = useState({
        email: '',
        password: ''
    });

    const [snack, setSnack] = useState({
        open: false,
        severity: '',
        message: ''
    });

    const loginSchema = yup.object().shape({
        email: yup.string().required('Email is required.'),
        password: yup.string().required('Password is required.')
    })

    //console.log(loginSchema)

    useEffect(() => {
        if (!hasMounted.current) {
            const token = localStorage.getItem('token')
            if (token) {
                navigate('/')
            }
        }
        hasMounted.current = true;
    })

    const handleForm = (values) => {
        //console.log(values)
        setLoading(true)
        api.post(`${endpoints.auth.login}`, values, {

        }).then(res => {
            console.log(res);
            const token = res['data']['token']
            if (token) {
                localStorage.setItem('token', token);
                navigate('/')
            }
        }).catch(err => {
            console.log('LOGIN ERROR', err);
            setError(err);
            setLoading(false);
            setSnack((prevsnack) => ({
                ...prevsnack,
                open: true,
                message: err['message'],
                severity: 'error'
            }))
        }).finally(() => {
            setTimeout(() => {
                setSnack((prevsnack) => ({
                    ...prevsnack,
                    open: false,
                }))
            }, 3000)
        })
    }


    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            width="100%"
            height="100vh"
        >
            <Box
                p="1rem 0"
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
            >
                {/* <img src="https://coolit.tickiot.com/assets/logo.svg" width="128" alt="" /> */}
                <LocalShippingIcon sx={{ fontSize: '64px' }} />
                <Typography color={colors.greenAccent[600]} variant="h3" fontWeight="bold" sx={{ marginTop: '1rem' }}>
                    Welcome to Distributor's Portal
                </Typography>
                <Typography color={colors.grey[100]} variant="h5" sx={{ marginTop: '0.2rem' }}>
                    Please login to manage products
                </Typography>
            </Box>
            <Box
                width={xlScreen ? '30%' : '50%'}
                height={xlScreen ? '40%' : '50%'}
                sx={{
                    borderRadius: '10px'
                }}
            >

                <Formik
                    onSubmit={handleForm}
                    initialValues={initialValues}
                    validationSchema={loginSchema}
                >
                    {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                        <form
                            onSubmit={handleSubmit}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                padding: '2rem 0'
                            }}
                        >
                            <LoginForm
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                touched={touched}
                                errors={errors}
                                values={values}
                                state={loading}
                            />

                            <Box
                                display={'flex'}
                                justifyContent={'space-between'}
                                padding={'10px'}
                                width={'80%'}
                            >
                                <Link href="#" underline="none" color={colors.blueAccent[400]}>Forgot password?</Link>
                                <Link href="#" underline="none" color={colors.blueAccent[400]}>Create Account</Link>
                            </Box>

                            <Button
                                type="submit"
                                variant="contained"
                                color="secondary" style={{ marginTop: '1rem', width: '80%' }}
                            //disabled={loading || errors.password || errors.email}
                            >
                                {
                                    loading ? (<Loader height={'32px'} size={28} />) : 'Login'
                                }
                            </Button>
                        </form>
                    )}
                </Formik>

                <CustomSnackbar
                    isOpen={snack.open}
                    message={snack.message}
                    severity={snack.severity}
                />
            </Box>
            <Footer />
        </Box>
    )

}

export default Login;