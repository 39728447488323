import { Avatar, Box, Button, Card, List, ListItem, ListItemAvatar, ListItemText, TextField, Typography, useTheme } from "@mui/material"
import { tokens } from "../../theme";
import { Fragment, useContext } from "react";
import { CartContext } from "./cart-context";
import CurrencyFormatter from "../../utilities/currency-formatter";
//import Loader  from '../../components/Loader'
import LoadingButton from '@mui/lab/LoadingButton';
//icons
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';

const CartItemList = ({ item }) => {

    const { getCartItemQuantity, increaseItemQty, decreaseItemQty, removeFromCart, deleteItem } = useContext(CartContext);

    const quantity = getCartItemQuantity(item.id)

    const theme = useTheme();
    const colors = tokens(theme.palette.mode)

    const decreaseQty = () => {
        if (quantity === 1) {

        } else {
            decreaseItemQty(item.id)
        }
    }

    return (

        <Card
            sx={{
                backgroundColor: 'transparent',
                mb: '5px'
            }}
        >
            <Box
            //p={'10px'}
            >
                <List sx={{
                    display: 'flex',
                    gap: '10px',
                    bgcolor: 'transparent'
                }}>
                    <ListItem gap="5px">
                        <ListItemAvatar>
                            <Avatar src={item.img} />
                        </ListItemAvatar>
                        <ListItemText
                            primary={item.n}
                            secondary={
                                <Fragment>
                                    <Typography variant="body1" component="span" color={colors.grey[400]}>
                                        {CurrencyFormatter(item.p, 'USD')}/unit
                                    </Typography>
                                </Fragment>
                            }
                        />
                        <Box>
                            <Box
                                display={'flex'}
                                gap={'4px'}
                                justifyContent={'space-between'}
                                width={'100%'}
                            >
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    size="medium"
                                    sx={{
                                        height: '36px',
                                        minWidth: '36px'
                                    }}
                                    disabled={quantity === 1 || deleteItem}
                                    onClick={() => decreaseQty()}
                                >
                                    <RemoveIcon />
                                </Button>
                                <TextField
                                    value={quantity}
                                    sx={{ width: '60px' }}
                                    size="small"
                                    inputProps={{ min: 1, max: item.in_stock, style: { textAlign: 'center' } }}
                                />
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    size="medium"
                                    sx={{
                                        height: '36px',
                                        minWidth: '36px'
                                    }}
                                    onClick={() => increaseItemQty(item)}
                                    disabled={deleteItem}
                                >
                                    <AddIcon />
                                </Button>
                                <Box
                                    ml={'1.2rem'}
                                >
                                    {/* <Button
                                        variant="outlined"
                                        color="error"
                                        size="medium"
                                        sx={{
                                            height: '36px',
                                            minWidth: '36px'
                                        }}
                                        onClick={() => removeFromCart(item.id)}
                                    >
                                        {
                                            deleteItem ? '': <DeleteIcon />
                                        }
                                        
                                    </Button> */}
                                    <LoadingButton
                                        color="error"
                                        size="medium"
                                        sx={{
                                            height: '36px',
                                            minWidth: '36px'
                                        }}
                                        onClick={() => removeFromCart(item.id)}
                                        variant="outlined"
                                        loading={deleteItem}
                                    >
                                        <DeleteIcon />
                                    </LoadingButton>
                                </Box>

                            </Box>
                        </Box>
                    </ListItem>
                </List>
            </Box>
        </Card>
    )
}

export default CartItemList