import { Box, useTheme } from "@mui/material"
import { Oval } from "react-loader-spinner"
import { tokens } from "../theme";


const Loader = ({height, size, color}) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={height ? height:'60vh'}
        >
            <Oval
                height={size ? size:32}
                width={size ? size:32}
                color={color ? color.primary:colors.greenAccent[500]}
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor={color ? color.secondary:colors.greenAccent[300]}
                strokeWidth={2}
                strokeWidthSecondary={2}

            />
        </Box>
    )
}

export default Loader