import { Box, Button, Checkbox, FormControlLabel, FormGroup, Typography, useTheme } from "@mui/material"
import { tokens } from "../../theme";
import { useEffect, useRef, useState } from "react";



const FilterCategory = ({ products, filterChange }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [categories, setCategories] = useState([]);
    const [selectedCheckbox, setSelectedCheckbox] = useState(null)

    const hasMounted = useRef(false);

    useEffect(() => {
        if (!hasMounted.current) {

            const cat = [];
            products.forEach(p => {
                cat.push(p.cat)
            })
            setCategories([...new Set(cat)])
        }
        hasMounted.current = true;

    }, [categories])


    const handleCheckBox = (category) => {
        console.log(category)
        if (selectedCheckbox === category) {
            setSelectedCheckbox(null);
            filterChange(null)
        } else {

            setSelectedCheckbox(category)
            filterChange(category)
        }
    }

    return (
        <Box 
        sx={{
            marginTop: '1.8rem',
            width: '100%'
        }}
        >
            <Typography variant='h5' color={colors.grey[500]} fontWeight={'500'} marginBottom={'0.8rem'}>
                Category
            </Typography>
            <FormGroup>

                {
                    categories.map((cat, i) => (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    key={i}
                                    checked={selectedCheckbox === cat}
                                    onChange={() => handleCheckBox(cat)}
                                    sx={{
                                        '&.Mui-checked': {
                                            color: colors.greenAccent[400],
                                        }
                                    }}
                                />
                            }
                            label={cat}
                            key={i}
                        />
                    ))
                }
            </FormGroup>
        </Box>
    )
}

export default FilterCategory;