import { Box, Button, IconButton, Typography, useTheme } from "@mui/material"
import { tokens } from "../../theme";
import { useEffect, useRef, useState } from "react";
import api from "../../data/axios-api";
import { endpoints } from "../../data/api";
import Loader from "../../components/Loader";


import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CustomDialog from "../global/custom-dialog";
import UserConfirmation from "../global/user-confirmation";
import AddressForm from "./address-form";
import CustomSnackbar from "../global/custom-snackbar";

const Address = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [addresses, setAddress] = useState([]);
    const hasMounted = useRef(false);
    const [loading, setLoading] = useState(false);
    const [consent, setConsent] = useState(false);
    const [openDlg, setOpenDlg] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('');
    const [open, setOpen] = useState(false)
    const [aid, setAid] = useState('');
    const [showfrm, setShowfrm] = useState(false);
    const [edit, setEdit] = useState(false);
    const [selAddress, setSelAddress] = useState({})
    const [updating, setUpdating] = useState(false);
    const [isDeleted, setIsdeletd] = useState(false)

    useEffect(() => {

        if (!hasMounted.current) {
            setLoading(true)
            api.get(`${endpoints.user.address.get}`, {})
                .then(res => {
                    setAddress((prev) => res['data']);
                    setLoading(false)
                    console.log(addresses)
                })
                .catch(err => {
                    console.log(err);
                    setLoading(false)
                })
                .finally(() => { setLoading(false) })
        }
        hasMounted.current = true
    }, [addresses, showfrm, isDeleted]);


    const confirmUser = (address_id) => {
        //setLoading(true)
        console.log(address_id);
        setAid(address_id)
        setOpenDlg(true)
    }

    const userConsent = (value) => {
        console.log(value);
        if (!value) {
            setOpenDlg(false)
        } else if (value) {
            setOpenDlg(false)
            deleteAdd()
        }
        setConsent(value)
    }


    const deleteAdd = () => {
        console.log('ID: ', aid);
        setLoading(true);
        api.post(`${endpoints.user.address.delete}`, { address_id: aid })
            .then(res => {
                setLoading(false);
                setOpen(true);
                setMessage('Address deleted.');
                setSeverity('success');
                hasMounted.current = false;
                setIsdeletd(true)
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
                setOpen(true);
                setMessage('Failed to delete address!!');
                setSeverity('error');
            })
            .finally(() => { })

    }

    const handleForm = (values) => {
        console.log(values)
        values.mode ? update(values.data) : add(values.data)
    }

    const closeForm = () => {
        setShowfrm(false)
    }

    const addNewAddress = () => {
        setShowfrm(true)
        setEdit(false)
        setSelAddress('')
    }


    const editAddress = (address) => {
        console.log(address)
        setEdit(true);
        setShowfrm(true)
        setSelAddress(address)
    }

    const add = (data) => {
        setUpdating(true)
        api.post(`${endpoints.user.address.add}`, data)
            .then(res => {
                if (res['data']['status_code'] === 403) {
                    setOpen(true);
                    setMessage(res['data']['message']);
                    setSeverity('error');
                    setUpdating(false);
                } else {
                    setOpen(true);
                    setMessage('Address added successfuly.');
                    setSeverity('success');
                    setUpdating(false);
                    setShowfrm(false);
                    hasMounted.current = false;
                }
            })
            .catch(err => {
                console.log(err);
                setOpen(true);
                setMessage('Failed to add address.');
                setSeverity('error');
                setUpdating(false)
            })
            .finally(() => { })
    }
    const update = (data) => {
        setUpdating(true)
        api.post(`${endpoints.user.address.edit}`, data)
            .then(res => {
                setOpen(true);
                setMessage(res.data.message);
                setSeverity('success');
                setUpdating(false);
                setShowfrm(false);
                hasMounted.current = false;
            })
            .catch(err => {
                console.log(err);
                setOpen(true);
                setMessage('Failed to add address.');
                setSeverity('error');
                setUpdating(false)
            })
            .finally(() => { })
    }

    const handleSnack = () => {
        setOpen(false)
    }


    return (
        <>
            {
                loading ? (<Loader />) : !showfrm && !loading ? (

                    <Box
                        width={'100%'}
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                    >
                        <Box
                            borderBottom={`1px solid ${colors.grey[400]}`}
                            width={'60%'}
                            pb={'5px'}
                            pt={'1rem'}
                            display={'flex'}
                            justifyContent={'flex-end'}
                            alignItems={'center'}
                        >
                            <Button variant="outlined" color="secondary" onClick={addNewAddress} disabled={loading}>
                                <AddIcon /> New Address
                            </Button>
                        </Box>
                        {
                            addresses.length ? (
                                addresses.map((add, i) => (
                                    <Box
                                        width={'60%'}
                                        display={'flex'}
                                        flexDirection={'row'}
                                        justifyContent={'space-between'}
                                        alignItems={'center'}
                                        key={i}
                                        mb={'.5rem'}
                                        mt={'1rem'}
                                        bgcolor={colors.primary[400]}
                                        p={'10px'}
                                    >
                                        <Box
                                            display={'flex'}
                                            flexDirection={'column'}
                                        >
                                            <Typography variant="h4" fontWeight={500} >{add.uname + ' - ' + add.ph}</Typography>
                                            <p
                                                style={{ color: `${colors.grey[400]}` }}
                                            >
                                                {
                                                   'Attendant: ' +  add.attn + ', Address: ' + add.addr + ' - ' + add.zip
                                                }
                                            </p>
                                        </Box>
                                        <Box>
                                            <IconButton
                                                color="secondary"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    editAddress(add)
                                                }}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton color="error" onClick={(e) => {
                                                e.stopPropagation()
                                                confirmUser(add.address_id)
                                            }}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                ))
                            ) : (
                                <Box
                                    width={'100%'}
                                    p={'10px'}
                                    display={'flex'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                >
                                    <Typography variant="h5" color={colors.grey[300]} > No address found, add one.</Typography>
                                </Box>
                            )
                        }
                    </Box>
                ) : showfrm && !loading ? (
                    <AddressForm mode={edit} address={selAddress} closeForm={closeForm} handleForm={handleForm} loading={updating} />
                ) : ''
            }
            <CustomDialog open={openDlg} component={UserConfirmation} setConsent={userConsent} />
            <CustomSnackbar isOpen={open} message={message} severity={severity} handleClose={handleSnack} />
        </>

    )
}

export default Address