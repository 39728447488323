import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useContext, useEffect, useRef, useState } from "react";
import api from "../../data/axios-api";
import { endpoints } from "../../data/api";
import Loader from "../../components/Loader";
import Error from "../../components/error";
import OrderTable from "./order-table";
import { CartContext } from "../cart/cart-context";

const OrderList = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const hasMounted = useRef(false);
    const [orders, setOrders] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const { toggleCart } = useContext(CartContext)

    useEffect(() => {
        if (!hasMounted.current) {
            setLoading(true)
            api.post(`${endpoints.product.order.get}`, { "timeframe": "all_orders" })
                .then(res => {
                    console.log(res);
                    setOrders((prev) => res['data']);
                    console.log(orders)
                    setLoading(false)
                })
                .catch(err => {
                    console.log(err);
                    setLoading(false);
                    setError(err)
                })
                .finally(() => {
                    
                })
        }
        toggleCart(false)
        hasMounted.current = true;
    })

    return (
        <Box
            display={'flex'}
            justifyContent={'center'}
            width={'100%'}
            position={'relative'}
            gap={'10px'}
            pl={'4rem'}
            pr={'4rem'}
            paddingTop={'80px'}
            height={'100vh'}
        >
            {
                error !== '' ? (<Error apiError={error} />) : loading ? (<Loader />) : (
                    <Box
                        width={'100%'}
                    >
                        <OrderTable
                            orders={orders}
                        />
                    </Box>
                )
            }
        </Box>
    )
}

export default OrderList;