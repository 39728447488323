import jwt_decode from "jwt-decode";
import { Outlet, Navigate } from "react-router-dom";

const ProtectedRoutes = () => {
    const token = localStorage.getItem('token'); // Get the JWT from local storage=
    let isAuthenticated = false;
  
    if (token) {
      const decodedToken = jwt_decode(token);
      const currentTime = Date.now() / 1000; // Convert current time to seconds
  
      if (decodedToken.exp > currentTime) {
        isAuthenticated = true; // Token is valid and not expired
      } else {
        // Token expired, clear storage and redirect to login
        localStorage.removeItem('token');
        isAuthenticated = false;
        window.location.replace('/')
      }
    } else {
        isAuthenticated = false;
    }
  
    return isAuthenticated ? (
     <Outlet/>
    ) : (
      <Navigate to="/login" replace={true} />
    );
  };

export default ProtectedRoutes;
