import { Box, Button } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";

import '../cart/cart.css';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const ProductNavigateButton = () => {

    const [animate, setAnimate] = useState(false);
    return (
        <Box>
            <Button
                variant="clear"
                size="small"
                color="secondary"
                style={{ gap: '5px' }}
                onMouseEnter={() => setAnimate(true)}
                onMouseLeave={() => setAnimate(false)}
                component={Link}
                to="/"
                sx={{
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                }}
            >
                <KeyboardBackspaceIcon />
                <span className={`animated-button ${animate ? 'slide-in visible' : ''}`}>Products</span>
            </Button>
        </Box>
    )
}

export default ProductNavigateButton;