import { Box, useTheme } from "@mui/material"
import { tokens } from "../../theme";

const Footer = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
         <Box
         display="flex"
         justifyContent={'center'}
         alignItems={'center'}
         color={colors.grey[400]}
         sx={{
             padding: '1rem',
             width: '100%',
             position: 'absolute',
             bottom: '2%'
         }}
         >
            Copyright © {new Date().getFullYear()} tickIoT. All Rights Reserved.
         </Box>
    )
}

export default Footer;