import { Box, Button, Checkbox, Chip, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, useTheme } from "@mui/material"
import { tokens } from "../../theme";
import { useState } from "react";

import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from "react-router-dom";
import ProductNavigateButton from "../global/product-navigate-button";

const OrderTable = ({ orders }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();
    const [selected, setSelected] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0)

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const handleSelect = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };
    const handleChangePage = (event, newPage) => {
        console.log('new Page: ', newPage)
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orders.length) : 0;

    const sortableOrders = orders.map(order => ({
        ...order,
        sortableDate: order.date.split('/').reverse().join('-'),
        sortableTime: order.time
    }));

    // Sort the orders based on date and time
    const sortedOrders = sortableOrders.slice().sort((a, b) => {
        const dateA = `${a.sortableDate} ${a.sortableTime}`;
        const dateB = `${b.sortableDate} ${b.sortableTime}`;
        return new Date(dateB) - new Date(dateA);
    });

    console.log(sortedOrders)

    const displayedOrders = sortedOrders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const viewDetails = (order) => {
        navigate(`/order/${order._id}`, { state: { ...order } })
    }


    return (
        <Box
            pt={'2rem'}
        >
            <ProductNavigateButton  />
            <Box
                pb={'1rem'}
                pt={'1rem'}
                width={'100%'}
                display={'flex'}
                flex={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                height={'54px'}
            >
                <Typography variant="h3" color={colors.blueAccent[500]}>
                    {
                        !selected.length ? `Orders (${orders.length})` : `Orders selected (${selected.length})`
                    }
                </Typography>
                {
                    selected.length ? <IconButton aria-label="delete" color="error" sx={{}}>
                        <DeleteIcon />
                    </IconButton> : null
                }
            </Box>
            <Paper sx={{ width: '100%', overflow: 'hidden', backgroundColor: 'transparent' }}>
                <TableContainer sx={{ maxHeight: '780px', height: '700px' }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                {/* <TableCell>Select</TableCell> */}
                                <TableCell>ID</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Time</TableCell>
                                <TableCell>Confirm Status</TableCell>
                                <TableCell>Payment Status</TableCell>
                                <TableCell>Details</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                displayedOrders.map((order, i) => {
                                    const isItemSelected = isSelected(order._id)
                                    return (
                                        <TableRow
                                            hover
                                            key={order._id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            {/* <TableCell component="th" scope="row">
                                                <Checkbox
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': order._id,
                                                    }}
                                                    onClick={(e) => handleSelect(e, order._id)}
                                                    sx={{
                                                        '&.Mui-checked': {
                                                            color: colors.greenAccent[500]
                                                        }
                                                    }}
                                                    selected={isItemSelected}
                                                />
                                            </TableCell> */}
                                            <TableCell component="th" scope="row">
                                                {order._id}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {order.date}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {order.time}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <Chip label={order.isConfirmed ? 'Confirmed' : 'Not confirmed'} color={order.isConfirmed ? 'success' : 'error'} variant="outlined" />
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <Chip label={order.isPaid ? 'Paid' : 'Not Paid'} color={order.isPaid ? 'success' : 'error'} variant="outlined" />

                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <Button variant="text" color="secondary" onClick={() => viewDetails(order)}>
                                                    View details
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    )

                                })
                            }
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={7} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 20, 30]}
                    component="div"
                    count={orders.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    )

}

export default OrderTable