import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";

const Address = ({ address, label }) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode)

    return (
        <Box
        width={'100%'}
        >
            <Typography variant="h5" fontWeight={500}>{label}: </Typography>
            <Box
                width={'100%'}
                backgroundColor={colors.grey[900]}
                height={'54px'}
                display={'flex'}
                alignItems={'center'}
                p={'5px'}
                mt={'15px'}
            >
                {address['uname'] + ', ' + address.addr + ', Phone: ' + address.ph + ', zip: ' + address.zip}
            </Box>
        </Box>
    )

}

export default Address;