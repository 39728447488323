import { Box, Dialog, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Oval } from "react-loader-spinner";
import { tokens } from "../../theme";
import UserAddress from "../user/user-address";


const CustomDialog = ({ open, onClose, isLoader, title, content, actions, setOpenDialog, component: Component, setConsent }) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClose = () => {
        if(!isLoader) {
            setOpenDialog(true)
        }
    }

    const onConfirm = () => {

    }

    return (
        <Box
           
        >
            {
                isLoader ? <Dialog open={open} onClose={onClose} disableBackdropClick={true} size="md">
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        gap={'15px'}
                        height={'80px'}
                        pl={'10px'}
                        pr={'10px'}
                    >
                        <Oval
                            height={32}
                            width={32}
                            color={colors.greenAccent[500]}
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor={colors.greenAccent[200]}
                            strokeWidth={3}
                            strokeWidthSecondary={3}

                        />
                        <Typography variant="h4" color={colors.grey[400]}>{content}</Typography>
                    </Box>
                </Dialog>
                    :
                    <Dialog
                        fullScreen={fullScreen}
                        open={open} onClose={onClose}
                        maxWidth={'md'}
                        
                    >
                        <Box>
                            <Component setOpenDialog={setOpenDialog} setConsent={setConsent} />
                        </Box>
                    </Dialog>
            }
        </Box>

    )
}


export default CustomDialog;