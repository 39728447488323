import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import * as yup from 'yup';
import { Formik } from "formik";
import { tokens } from "../../theme";
import { useEffect, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import AddressForm from "./address-form";
import Loader from "../../components/Loader";
import api from "../../data/axios-api";
import { endpoints } from "../../data/api";
import Error from "../../components/error";
import CustomSnackbar from "../global/custom-snackbar";

const AddUserAddress = ({ data, SetAddAddress, httpState  }) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    //const [mode, setMode] = useState('new')
    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('');
    const [message, setMessage] = useState('')

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [initstate, setInitstate] = useState({
        uname: "",
        addr: '',
        ph: '',
        zip: '',
        type: ''
    });

    const addressSchema = yup.object().shape({
        uname: yup.string().required('Name is required')
        .min(5, 'Min 5 & Max 100 characters are allowed.'),
        attn: yup.string().required('Name is required')
        .min(5, 'Min 5 & Max 100 characters are allowed.')
        .max(30, 'Min 5 & Max 100 characters are allowed.'),
        addr: yup.string().required('Address is required.')
        .min(5, 'Min 5 & Max 100 characters are allowed.')
        .max(100, 'Min 5 & Max 100 characters are allowed.'),
        ph: yup.string().required('Phone number is required').matches(/\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/, 'Invalid Phone number.'),
        zip: yup.string().required('Zip code is required').matches(/^\d{5}(?:[-\s]\d{4})?$/, 'Invalid zip code.'),
        type: yup.string().required('Please select address type.')
    });



    useEffect(() => {
        console.log('edit add', data)
        if (data.mode === 'edit') {
            //setMode
            //console.log('edit add', data)
            setInitstate(prevadd => ({
                ...prevadd,
                uname: data.address.uname, 
                addr: data.address.addr,
                ph: data.address.ph,
                zip: data.address.zip,
                type: data.address.type,
                attn: data.address.attn
                
            }))
        } else {
            setInitstate(prevadd => ({
                ...prevadd,
                uname: "",
                addr: "",
                ph: "",
                zip: "",
                type: '',
                attn: ''
            }))
        }
    }, [data]);

    const handleSnackClose = () => {
        setOpen(false)
    }


    const handleForm = (values) => {
        console.log('Mode: ', values, data)
        if (data.mode === 'new') {
            addnewAdd(values)
        } else if (data.mode === 'edit') {
            editAdd({...values, address_id: data.address.address_id})
        }

    }

    const addnewAdd = (values) => {
        console.log('add new add..')
        setLoading(true)
        api.post(`${endpoints.user.address.add}`, values)
            .then(res => {

                if(res['data']['status_code'] === 403) {
                    setLoading(false);
                    setOpen(true);
                    setSeverity('error');
                    setMessage(res['data']['message'])
                } else {
                    setLoading(false);
                    httpState(res.data)
                    close()
                }
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
                setError(err);
                setOpen(true);
                setSeverity('error');
                setMessage('Failed to add address.')
            })
            .finally(() => { })
    }

    const editAdd = (values) => {
        console.log('on edit click: ', values);
        
        setLoading(true)
        api.post(`${endpoints.user.address.edit}`, values)
            .then(res => {
                console.log(res)
                setOpen(true);
                setMessage(res['data']['message']);
                setSeverity('success')
                httpState(res.data)
                close();
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
                setError(err);
                setOpen(true);
                setMessage('Failed to update address!!');
                setSeverity('error')
            })
            .finally(() => { })
    }


    const close = () => {
        SetAddAddress()
    }

    return (
        <>
            <Box
                width={'100%'}
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
                p={'15px'}
            >
                <Typography variant="h4" color={'secondary'}>
                    {
                        data.mode === 'new' ? 'Add Address': 'Update address'
                    }
                </Typography>
                <IconButton
                    aria-label="close"

                    onClick={close}
                >
                    <CloseIcon />
                </IconButton>
            </Box>
            <Box
                width={'100%'}
                display={'flex'}
                flexDirection={'column'}
                p={'15px'}
            >
                {
                    error !== '' ? (
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            width={'100%'}
                            alignItems={'center'}
                            gap={'10px'}
                        >
                            <Error apiError={error} />
                            <Button
                                variant="outlined"
                                color="error"
                                onClick={() => setError('')}
                                style={{ width: '30%' }}
                            >
                                Retry
                            </Button>
                        </Box>
                    ) :
                        <Formik
                            onSubmit={handleForm}
                            initialValues={initstate}
                            validationSchema={addressSchema}
                            enableReinitialize
                        >
                            {({ values, errors, touched, handleBlur, handleChange, handleSubmit, isValid }) => (
                                <form
                                    onSubmit={handleSubmit}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        padding: '2rem 0'
                                    }}
                                >
                                    <AddressForm
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        touched={touched}
                                        errors={errors}
                                        values={values}
                                        state={loading}
                                    />


                                    <Box
                                        width={'100%'}
                                        display={'flex'}
                                        justifyContent={'flex-end'}
                                    >
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="secondary" style={{ marginTop: '1rem' }}
                                            disabled={!isValid || loading}
                                        >
                                            {
                                                loading ? (<Loader height={'32px'} size={28} />) : data.mode === 'new' ? 'Save':'Update'
                                            }
                                        </Button>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                }
                <CustomSnackbar isOpen={open} severity={severity} message={message} handleClose={handleSnackClose} />
            </Box>
        </>
    )
}

export default AddUserAddress;