
const CurrencyFormatter = (price, symbol) => {

const CURRENCY_FORMATTER = new Intl.NumberFormat(undefined, {
    currency: symbol, 
    style: 'currency'
})
    return CURRENCY_FORMATTER.format(price)
}

export default CurrencyFormatter;