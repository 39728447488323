import { createContext, useEffect, useRef, useState } from "react";
import { endpoints } from "../../data/api";
import api from "../../data/axios-api";
import CustomSnackbar from "../global/custom-snackbar";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const [isCart, setCart] = useState(false);
  const hasMounted = useRef(false);
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [loading, setLoading] = useState(false);
  const [reFetch, setReFetch] = useState(false);
  const [deleteItem, isDeletingItem] = useState(false);

  useEffect(() => {
    if (!hasMounted.current) {
      setLoading(true);
      api
        .get(`${endpoints.product.cart.get}`, {})
        .then((res) => {
          const data = res["data"];
          console.log(data);
          if (data["order_info"].length === 0) {
            setCartItems([]);
            setIsOpen(true);
            setMessage("No items in the cart.");
            setSeverity("info");
          } else {
            setCartItems((currentItems) => data["order_info"]);
            console.log("From cart: ", cartItems);
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response?.status === 401) {
            localStorage.removeItem("token");
          }
          setLoading(false);
        })
        .then(() => {
          setLoading(false);
        });
    }
    hasMounted.current = true;
  }, [cartItems]);

  const getCartItemQuantity = (id) => {
    const qty = cartItems.find((item) => item.id === id)?.units || 0;
    //console.log(qty)
    return qty
  };

  
  const increaseItemQty = async (product) => {
    setCartItems((currentItems) => {
      const existingItem = currentItems.find((item) => item.id === product.id);

      if (!existingItem) {
        const newCartItem = { ...product, units: 1 };
        updateCart(newCartItem, "add")
        .then(res => {
          console.log('asdds', res)
        })
        return [...currentItems, newCartItem];
      } else {
        const updatedItems = currentItems.map((item) => {
          if (item.id === product.id) {
            const modified = { ...item, units: parseFloat(item.units) + 1 };
            updateCart(modified);
            return modified;
          } else {
            return item;
          }
        });
        return updatedItems;
      }
    });
  };

  const decreaseItemQty = (id) => {
    setCartItems((currentItems) => {
      if (currentItems.find((item) => item.id === id)?.units === 1) {
        return currentItems.filter((item) => item.id !== id);
      } else {
        const updatedItems = currentItems.map((item) => {
          if (item.id === id) {
            return { ...item, units: parseFloat(item.units) - 1 };
          } else {
            return item;
          }
        });
        const index = updatedItems.findIndex((item) => item.units === 0);
        console.log(index)
        if (index !== -1) {
          updatedItems.splice(index, 1);
        }
        const ci = updatedItems.find((item) => item.id === id);
        updateCart({ id: ci.id, units: ci.units });
        return updatedItems;
      }
    });
  };

  //const addItemToCart = (items) => {};

  const updateCart = (items, mode) => {
    //console.log(items)
    return new Promise((resolve, reject) => {
      const state = mode === "add" ? true : false;
      api
        .post(
          `${
            state ? endpoints.product.cart.add : endpoints.product.cart.update
          }`,
          { id: items.id, units: items.units }
        )
        .then((res) => {
          //setReFetch(true);
          setMessage("Cart updated.");
          setIsOpen(true);
          setSeverity("success");
          hasMounted.current = false;
          resolve()
        })
        .catch((err) => {
          console.log(err);
          setMessage("Failed to update cart.");
          setIsOpen(true);
          setSeverity("error");
          reject();
        })
        .finally(() => {
          //setReFetch(false);
          hasMounted.current = false;
        });
    });
  };

  const removeFromCart = (id) => {
    console.log("remove from cart: ", id);
    
    deleteCartItem(id)
    .then(res => {
      setCartItems((currentItem) => currentItem.filter((item) => item.id !== id));
      isDeletingItem(false);
    })
    .catch(err => {
      console.log(err);
      isDeletingItem(false);
    })
  };

  const clearCart = () => {
    setCartItems([]);
  };

  const toggleCart = (state) => {
    setCart(state);
    //console.log('isCart: ', isCart);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const deleteCartItem = (id) => {
    isDeletingItem(true);
    return new Promise((resolve, reject) => {
      api
        .post(`${endpoints.product.cart.delete}`, { id: id })
        .then((res) => {
          isDeletingItem(false);
          setMessage("Item removed.");
          setIsOpen(true);
          setSeverity("success");
          resolve()
        })
        .catch((err) => {
          console.log(err);
          reject()
        })
        .finally(() => {
          hasMounted.current = false;
        });
    })
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        removeFromCart,
        getCartItemQuantity,
        increaseItemQty,
        decreaseItemQty,
        loading,
        toggleCart,
        isCart,
        deleteItem,
        clearCart
      }}
    >
      {children}
      <CustomSnackbar
        isOpen={isOpen}
        message={message}
        handleClose={handleClose}
        severity={severity}
      ></CustomSnackbar>
    </CartContext.Provider>
  );
};

/* 
const addToCart = (item) => {
  setCartItems((prevItems) => {
    let itemExists = false;

    const updatedItems = prevItems.map((cartItem) => {
      if (cartItem.id === item.id) {
        // Item already exists, update its units
        cartItem.units += item.units;
        updateCart(cartItem);
        itemExists = true;
      }
      return cartItem;
    });

    if (!itemExists) {
      // Item is new, add it to the cart
      const newItem = { ...item };
      addItemToCart(newItem);
      updatedItems.push(newItem);
    }

    return updatedItems;
  });
};


*/
